/* eslint-disable import/prefer-default-export */
import currencyUtils from 'utils/currency'
import {
  ADULT_CATEGORIES,
  CATEGORIES_ID,
  CHILDREN_CATEGORIES,
} from 'contexts/giftGuide/content'

import { execSome, FILTER_TYPE } from 'contexts/giftGuide/filters/constants'

const translateTitle = (filters, settings) => {
  if (!filters || filters.length === 0) return ''

  const { id, title } = filters[0]

  const allCategories = [...ADULT_CATEGORIES, ...CHILDREN_CATEGORIES]
  const matchedCategory = allCategories.find((category) => category.id === id)

  if (!matchedCategory) {
    return title
  }

  switch (matchedCategory.labelId) {
    case ADULT_CATEGORIES[0].labelId:
      return settings.categoryWomen
    case ADULT_CATEGORIES[1].labelId:
      return settings.categoryMen
    case CHILDREN_CATEGORIES[0].labelId:
      return settings.categoryGirls
    case CHILDREN_CATEGORIES[1].labelId:
      return settings.categoryBoys
    default:
      return ''
  }
}

export const getActiveFiltersLabel = (filters, settings) => {
  const multipleFiltersSelected = filters.length > 1
  return multipleFiltersSelected
    ? ` (${filters.length}) ${settings.filtersLabel}`
    : translateTitle(filters, settings)
}

const translateHeader = (content, settings) => {
  switch (content) {
    case CATEGORIES_ID.MENS:
      return settings.categoryMen
    case CATEGORIES_ID.WOMEN:
      return settings.categoryWomen
    case CATEGORIES_ID.GIRLS:
      return settings.categoryGirls
    case CATEGORIES_ID.BOYS:
      return settings.categoryBoys
    default:
      return content
  }
}

export const getAccordionHeaderLabel = (
  contentArray = [],
  fallbackContent = '',
  settings
) => {
  if (contentArray.length < 1) return fallbackContent
  if (contentArray.length < 2) return translateHeader(contentArray[0], settings)
  return `${contentArray[0]} (+${contentArray.length - 1})`
}

export const getCurrencySymbol = (villageCurrency, price) => {
  if (!villageCurrency) return ''

  if (!price && villageCurrency === 'EUR')
    return currencyUtils[villageCurrency]('0', { simple: true }).split(
      /[0-9]/gi
    )[currencyUtils[villageCurrency].length - 1]

  if (!price)
    return currencyUtils[villageCurrency]('0', { simple: true }).split(
      /[0-9]/gi
    )[0]

  return currencyUtils[villageCurrency](price, { simple: true })
}

export const onActivePricesClick = (
  activeFilters,
  displayCategories,
  displayPrices
) => {
  const hasPrices = execSome(
    activeFilters,
    ({ type }) => type === FILTER_TYPE.PRICE
  )
  const hasCategories = execSome(
    activeFilters,
    ({ type }) => type === FILTER_TYPE.CATEGORY || FILTER_TYPE.EDITION
  )

  if (hasPrices && hasCategories) {
    return () => {
      displayCategories(true)
      displayPrices(true)
    }
  }

  if (hasPrices) return () => displayPrices(true)

  if (hasCategories) return () => displayCategories(true)

  return () => null
}
