import React, { useState, useReducer } from 'react'
import PropTypes from 'prop-types'

import {
  contextDefinition,
  contextInitialState,
  islandInitialState,
  islandReducer
} from './constants'

export const ProductGuideSettingsContext = ({ children, settings }) => {
  
  const [selectedProduct, setSelectedProduct] = useState(contextInitialState.selectedProduct)
  const selectProduct = product => setSelectedProduct(() => product)
  const clearSelectedProduct = () => setSelectedProduct(() => null)

  const [displayCategories, setDisplayCategories] = useState(contextInitialState.displayCategories)
  const toggleDisplayCategories = () => setDisplayCategories(state => !state)

  const [displayPrices, setDisplayPrices] = useState(contextInitialState.displayPrices)
  const toggleDisplayPrices = () => setDisplayPrices(state => !state)

  const [islandState, dispatch] = useReducer(islandReducer, islandInitialState)

  return (
    <contextDefinition.Provider
      value={{
        ...contextInitialState,
        ...settings,
        selectedProduct,
        selectProduct,
        clearSelectedProduct,
        islandState,
        dispatchIslandAction: dispatch,
        displayCategories,
        setDisplayCategories,
        toggleDisplayCategories,
        displayPrices,
        setDisplayPrices,
        toggleDisplayPrices,
      }}
    >
      {children}
    </contextDefinition.Provider>
  )
}

ProductGuideSettingsContext.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object, null]),
  settings: {
    heroBg: PropTypes.string,
    floatingIslandBg: PropTypes.string,
    categoryFilterBg: PropTypes.string,
    categoryFilterMobileBg: PropTypes.string,
    primaryBg: PropTypes.string,
    secondaryBg: PropTypes.string,
    nodeLocale: PropTypes.string,
    village: PropTypes.oneOfType([PropTypes.object])
  },
}

ProductGuideSettingsContext.defaultProps = {
  children: null,
  settings: contextInitialState,
}

export * from './constants'

export default ProductGuideSettingsContext