import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Spacing40 } from 'styles/sharedStyle'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'

import { contextDefinition as contentContext } from 'contexts/giftGuide/content'

import {
  contextDefinition as filtersContext,
  FILTER_TYPE,
  SLUG_TYPES,
  isEmpty,
  filterByEdition,
  handleHashSlugChange,
  updateHashUrl,
} from 'contexts/giftGuide/filters'

import {
  CategoryCopy,
  CategoryTitle,
  HeroWrapper,
  HeroTitle,
  HeroText,
  NavigationItem,
  HeroContainer,
  NavContentContainer,
  NavContainer,
  ItemBackgroundImage,
  ItemImage,
  ItemBackgroundOpactiy,
  CustomTitle,
  CustomDescription,
  DescriptionWrapper,
} from './GiftGuidesHeroSimple.style'

const GiftGuidesHero = ({ title, description, hexValue, villageName }) => {
  const filters = useContext(filtersContext)
  const { editions } = useContext(contentContext)

  const onSetActive = (item) => {
    const itemSlug = isEmpty(item.slug) ? item.contentful_id : item.slug
    filters.onSelectFilter({
      filterObj: {
        id: item.id,
        type: FILTER_TYPE.EDITION,
        title: item.title,
        filter: (product) =>
          filterByEdition(product, item.id, filters.editionProductLink),
      },
      urlResolver: () => {
        updateHashUrl(
          handleHashSlugChange(
            filters.location.hash,
            (hashSection) => `${hashSection},${itemSlug}`,
            SLUG_TYPES.CATEGORIES,
            itemSlug
          )
        )
      },
    })

    const element = document.getElementById('gift-guide-grid')

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      })
    }
  }

  const hasNavItems = editions.length > 0

  const renderItem = (item, index) => {
    const { image } = item
    const isActive = filters.active.some(({ id }) => id === item.id)
    return (
      <NavigationItem
        activeClass="active"
        to={item.id}
        key={index}
        spy
        smooth
        offset={-200}
        duration={1000}
        isActive={isActive}
        itemId={item.id}
        onClick={() => {
          trackGTM(
            'Gift Guides',
            'gift guide navigation',
            'category  click',
            item.title
          )
          GA4('gift_guide_category_click', {
            headline: item.title,
            component_headline: title,
            village_name: villageName,
          })
          onSetActive(item, isActive)
        }}>
        <ItemBackgroundImage src={image}>
          <ItemBackgroundOpactiy />
          <ItemImage
            image={image?.portrait?.gatsbyImageData}
            altText={image?.altText}
          />
        </ItemBackgroundImage>
        {item.titleCopy && <CategoryCopy>{item.titleCopy}</CategoryCopy>}
        <CategoryTitle>{item.title}</CategoryTitle>
      </NavigationItem>
    )
  }

  return (
    <HeroContainer hasNavigation={hasNavItems}>
      <HeroWrapper hexValue={hexValue} hasNavigation={hasNavItems}>
        <CustomTitle as={HeroTitle}>{title}</CustomTitle>
        <Spacing40 />
        <DescriptionWrapper>
          <CustomDescription as={HeroText}>{description}</CustomDescription>
        </DescriptionWrapper>
      </HeroWrapper>
      {hasNavItems && (
        <NavContainer>
          <NavContentContainer editionsLength={editions.length}>
            {editions.map((item, index) => renderItem(item, index))}
          </NavContentContainer>
        </NavContainer>
      )}
    </HeroContainer>
  )
}

GiftGuidesHero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hexValue: PropTypes.string,
  villageName: PropTypes.string,
}

GiftGuidesHero.defaultProps = {
  title: '',
  description: '',
  hexValue: '',
  villageName: '',
}

export default GiftGuidesHero
