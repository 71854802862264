/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { rem } from 'polished'

import theme from 'styles/theme'
import { Container } from 'components/FloatingIsland/FloatingIsland.style'
import { OutlinedButton, IconButton as BaseIconButton } from '../style'

const gridGap = rem(21)

export const Append = styled(Container)`
  padding: ${rem(20)} ${rem(32)};
  backdrop-filter: initial;
  box-shadow: initial;
  animation-duration: 0.35s;

  &:first-child + & {
    padding-top: ${rem(28)};
  }

  & + & {
    padding-top: ${rem(12)};
  }
`

export const FloatingContainer = styled(Append)`
  position: sticky;
  top: 0;
  z-index: 2;
`

export const IconButton = styled(BaseIconButton)`
  svg {
    width: 100%;
    height: 100%;
  }
`

export const AppendLabel = styled.span`
  font-size: ${rem(20)};
  font-family: BrownStd-Light, serif;
  padding-top: ${(props) => (props?.pt ? rem(props.pt) : `0`)};
  padding-bottom: ${(props) =>
    props?.pb ? rem(props.pb) : `calc(${rem(40)} - ${rem(16)})`};
`

export const Grid = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(
    3,
    calc(((100% / 3) - ${gridGap}) + (${gridGap} / 3))
  );
  column-gap: ${gridGap};
  row-gap: ${rem(12)};

  ${theme.mediaQueries.large} {
    grid-template-columns: repeat(
      5,
      calc(((100% / 5) - ${gridGap}) + (${gridGap} / 5))
    );
  }
`

export const AppendButton = styled(OutlinedButton)`
  width: 100%;
  padding: ${rem(16)} ${rem(12)};
  align-items: center;
  column-gap: ${rem(6)};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${(props) => props?.activeBg ?? theme.colors.pastelGold};
    color: ${theme.colors.white};
    fill: ${theme.colors.white};
    stroke: ${theme.colors.white};
  }

  &:active:not(:disabled) {
    background-color: ${theme.colors.white};
    color: ${(props) => props?.bg ?? theme.colors.pastelGold};
    fill: ${(props) => props?.bg ?? theme.colors.pastelGold};
    stroke: ${(props) => props?.bg ?? theme.colors.pastelGold};
  }

  & > span {
    display: block;
    text-transform: none;
    overflow: hidden;
  }

  ${(props) =>
    props?.small &&
    `
    padding: ${rem(12)};
    max-width: calc((((100% / 3) - ${gridGap}) + (${gridGap} / 3)) * 0.77);

    ${theme.mediaQueries.medium} {
      max-width: calc((((100% / 5) - ${gridGap}) + (${gridGap} / 5)) * 0.77);
    }
  `}
`
