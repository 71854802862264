/* eslint-disable camelcase */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import VideoPlayer from 'components/VideoPlayer'
import { contextDefinition as settingsContext } from 'contexts/giftGuide/settings'
import {
  contextDefinition as filterContext,
  FILTER_TYPE,
  SLUG_TYPES,
  updateHashUrl,
  handleHashSlugChange,
  filterByEdition,
  isEmpty,
} from 'contexts/giftGuide/filters'
import {
  GiftGuideCardContainer,
  GiftGuideCardWrapper,
  GiftGuideCardImg,
  TextWrapper,
  CrossSellingCardTitle,
} from './NewGiftGuideProductCard.style'

const getAlternativeBackground = ({ altImage, richMedia }) => {
  if (richMedia?.portrait) {
    return richMedia
  }

  return altImage
}

const PromoGiftCard = ({
  id,
  contentful_id,
  type,
  index,
  title,
  titleCopy,
  image,
  slug,
  altImage,
  richMedia,
  showTitleInRichMedia,
  isModalOpen,
  columnIndex,
  isCardSelected,
}) => {
  const settings = useContext(settingsContext)
  const filters = useContext(filterContext)
  const hasVideo = richMedia && richMedia?.videoPortrait
  const cardImage = getAlternativeBackground({ altImage, richMedia }) ?? image
  const itemSlug = isEmpty(slug) ? contentful_id : slug
  return (
    <GiftGuideCardContainer
      columnIndex={columnIndex}
      id={`promo-${type}-${index}`}
      selected={isModalOpen}
      isCardSelected={isCardSelected}
      onClick={() => {
        filters.onSelectFilter({
          filterObj: {
            id,
            type: FILTER_TYPE.EDITION,
            title,
            filter: (product) =>
              filterByEdition(product, id, filters.editionProductLink),
          },
          urlResolver: () => {
            updateHashUrl(
              handleHashSlugChange(
                filters.location.hash,
                (hashSection) => `${hashSection},${itemSlug}`,
                SLUG_TYPES.CATEGORIES,
                itemSlug
              )
            )
          },
        })
      }}>
      <GiftGuideCardWrapper>
        {hasVideo ? (
          <VideoPlayer
            name={richMedia.name}
            controls={false}
            loop
            muted
            video={{
              portrait: richMedia.videoPortrait,
              landscape: richMedia.videoLandscape,
              opacity: 1,
            }}
            autoplay
            image={image}
            videoMode="portrait"
          />
        ) : (
          <GiftGuideCardImg
            selected={isModalOpen}
            cardColor={settings.productCardOverlayColor}
            image={cardImage?.portrait?.gatsbyImageData}
            alt={cardImage?.altText ?? 'offer-image'}
          />
        )}
        <TextWrapper>
          {(!hasVideo || (hasVideo && showTitleInRichMedia)) && (
            <>
              <CrossSellingCardTitle>{titleCopy}</CrossSellingCardTitle>
              <CrossSellingCardTitle fontStyle="italic">
                {title}
              </CrossSellingCardTitle>
            </>
          )}
        </TextWrapper>
      </GiftGuideCardWrapper>
    </GiftGuideCardContainer>
  )
}

PromoGiftCard.propTypes = {
  id: PropTypes.string.isRequired,
  contentful_id: PropTypes.string.isRequired,
  index: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
  titleCopy: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.shape({
    altText: PropTypes.string,
    portrait: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    landscape: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    __typename: PropTypes.string,
  }),
  altImage: PropTypes.shape({
    altText: PropTypes.string,
    portrait: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    landscape: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    __typename: PropTypes.string,
  }),
  richMedia: PropTypes.shape({
    name: PropTypes.string,
    videoPortrait: PropTypes.string,
    videoLandscape: PropTypes.string,
    __typename: PropTypes.string,
  }),
  isModalOpen: PropTypes.bool,
  columnIndex: PropTypes.number.isRequired,
  isCardSelected: PropTypes.bool,
  showTitleInRichMedia: PropTypes.bool,
}

PromoGiftCard.defaultProps = {
  index: 0,
  type: '',
  title: '',
  titleCopy: '',
  slug: '#',
  image: {
    altText: '',
    portrait: { gatsbyImageData: {} },
    landscape: { gatsbyImageData: {} },
    __typename: '',
  },
  altImage: null,
  richMedia: null,
  isModalOpen: false,
  isCardSelected: false,
  showTitleInRichMedia: false,
}

export default PromoGiftCard
