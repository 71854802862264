import { useCallback, useEffect, useState } from 'react'
import { useThrottle } from './useOptimizers'

const ELEMENT_VISIBLE = false

const OPTIONS = {
  parentRef: null,
  visibilityPercentages: 0.2,
  onVisibilityChange: null,
  onHidden: null,
  onVisible: null,
}

const EMPTY_VISIBILITY = {
  top: null,
  right: null,
  bottom: null,
  left: null,
}

const getElementRef = (element) => {
  if (!element) return undefined

  if (typeof element === 'string') return document.getElementById(element)

  if (element?.current) return element.current

  return undefined
}

export const getElementVisibility = (element = null) => {
  if (typeof window === 'undefined' || !element) return EMPTY_VISIBILITY

  const DOMRect = element?.getBoundingClientRect() ?? EMPTY_VISIBILITY

  return {
    top: DOMRect.top,
    right: DOMRect.right,
    bottom: DOMRect.bottom,
    left: DOMRect.left,
  }
}

export const scrollIntoView = (element, threshold = 350) => {
  if (typeof window === 'undefined') return null

  const elementDOMRef = getElementRef(element)

  const elementDOMRect = getElementVisibility(elementDOMRef)

  if (
    elementDOMRect.bottom !== null &&
    Number(elementDOMRect.bottom) < threshold
  ) {
    elementDOMRef.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'end',
    })
  }

  return null
}

/**
 *
 * @param {{current: any}=} elementRef
 * @param {any=} options
 */
export default (elementRef, options = {}) => {
  const {
    parentRef = OPTIONS.parentRef,
    visibilityPercentages = OPTIONS.visibilityPercentages,
    onVisibilityChange = OPTIONS.onVisibilityChange,
    onHidden = OPTIONS.onHidden,
    onVisible = OPTIONS.onVisible,
  } = options

  const [isVisible, setIsVisible] = useState(ELEMENT_VISIBLE)

  const onChangeTrigger = useCallback(([entry]) => {
    setIsVisible(() => entry.isIntersecting)
    if (onVisibilityChange) onVisibilityChange(entry.isIntersecting)
    if (!entry.isIntersecting && onHidden) onHidden()
    if (entry.isIntersecting && onVisible) onVisible()
  }, [])
  const throttledListener = useThrottle(onChangeTrigger, 1000)

  useEffect(() => {
    if (!elementRef.current) return () => null
    const triggerFunction =
      visibilityPercentages < 0.3 ? throttledListener : onChangeTrigger

    const observer = new IntersectionObserver(triggerFunction, {
      root: parentRef,
      threshold: visibilityPercentages,
    })

    observer.observe(elementRef.current)

    return () => observer.disconnect()
  }, [elementRef.current, parentRef, visibilityPercentages])

  return isVisible
}
