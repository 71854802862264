/* eslint-disable import/prefer-default-export */
import theme from 'styles/theme'
import styled, { css, keyframes } from 'styled-components'
import { rem } from 'polished'
import { isMobile } from 'react-device-detect'
import { Title60, Title32 } from 'components/Typography'
import { Container } from 'styles/sharedStyle'

import { PhantonButton } from 'components/GiftGuideFloatingIsland/style'

const showGrid = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
    max-width: 90%;
  }
  to {
    opacity: 1;
    display: grid;
    max-width: 1200px;
  }
`

export const Section = styled.section`
  position: relative;
  padding-top: ${rem(28)};

  ${theme.mediaQueries.medium} {
    padding-top: ${rem(86)};
  }
`

export const GridSection = styled.section`
  position: relative;
  width: 100%;
  margin-top: 0;

  ${(props) =>
    props?.shouldAnimate &&
    css`
      min-height: calc((295px * 2) + 32px);

      ${theme.mediaQueries.medium} {
        min-height: calc(((25vw + 7.5vw) * 2.2) + 58px);
      }
    `}
`

export const GridContainer = styled(Container)`
  z-index: 1;
  padding-top: ${rem(55)};
  gap: 15px;
  display: none;
  grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile */
  position: relative;
  max-width: 1200px;
  opacity: 0;

  ${(props) =>
    props?.showFullGrid &&
    css`
      ${props?.shouldAnimate &&
        css`
          animation-fill-mode: forwards;
          animation: ${css`
              ${showGrid}`} 2s 1;
        `}
      display: grid;
      opacity: 1;
    `}

  ${theme.mediaQueries.mobile} {
    padding-top: ${rem(75)};
    padding-left: ${theme.space[4]};
    padding-right: ${theme.space[4]};
  }

  ${theme.mediaQueries.medium} {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for web */
    gap: 29px;
    transition: opacity 0.5s ease, transform 1s ease, max-width 1s ease;
    padding-top: ${rem(96)};
  }
`
export const EmptyStateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justfy-content: center;
  align-items: center;
  padding-top: 175px;
  row-gap: 1rem;

  ${theme.mediaQueries.medium} {
    padding-top: 200px;
  }
`

export const EmptyStateTitle = styled(isMobile ? Title32 : Title60)`
  font-weight: 200;
  opacity: 0.3;
`
export const EmptyStateAction = styled(PhantonButton)`
  text-decoration: underline;
  opacity: 0.65;
  color: ${(props) => props?.color};
`
