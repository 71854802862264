/* eslint-disable no-shadow */
import React, { useContext, useMemo } from 'react'
import { rem } from 'polished'
import {
  contextDefinition as contentContext,
  ADULT_CATEGORIES,
  CHILDREN_CATEGORIES,
} from 'contexts/giftGuide/content'
import { contextDefinition as settingsContext } from 'contexts/giftGuide/settings'
import {
  contextDefinition as filterContext,
  FILTER_TYPE,
  PRICE_TYPE,
  SLUG_TYPES,
  execSome,
  execFilter,
  filterByAdultGender,
  filterByChildrenGender,
  filterByEdition,
  filterByUnderPrice,
  filterByBetweenPrices,
  filterByAbovePrice,
  handleHashSlugChange,
  updateHashUrl,
  isSubstring,
  isEmpty,
  dynamicRegex,
} from 'contexts/giftGuide/filters'
import Accordion from 'components/AccordionCustom'
import CloseIcon from 'components/Icons/CloseIcon'
import FilterIcon from 'components/Icons/FilterIcon'
import {
  IslandRow,
  IslandRowGroup,
} from 'components/FloatingIsland/FloatingIsland.style'

import {
  getActiveFiltersLabel,
  getAccordionHeaderLabel,
  getCurrencySymbol,
  onActivePricesClick,
} from '../utils'
import FIAnimatedDisplayContainer from '../FIDisplayContainer'
import FIScrollListener from '../FIScrollListener'
import UpScrollListener from './UpScrollListener'
import BottomPanel from './bottomPanel'
import {
  CategoryButton,
  CategoryBtnText,
  CheckIcon,
  IconButton,
  OutlinedButton,
  PhantonButton,
} from '../style'
import {
  BottomPanelContent,
  BottomPanelSection,
  BottomPanelHeader,
  BottomPanelItem,
  ChevronIcon,
  FloatingContainer,
} from './style'

const PGFIMobile = () => {
  const settings = useContext(settingsContext)
  const filters = useContext(filterContext)

  const {
    adultCategories,
    abovePrice,
    childrenCategories,
    editions,
    underPrice,
  } = useContext(contentContext)

  const buttonStyleProps = useMemo(
    () => ({
      bg: settings?.primaryBg && `#${settings.primaryBg}`,
      activeBg: settings?.primaryBg && `#${settings.primaryBg}`,
      hoverBg: settings?.primaryBg && `#${settings.primaryBg}`,
      fontSize: rem(16),
    }),
    []
  )
  const {
    selectedEditions,
    activePrices,
    selectedAdultCat,
    selectedChildrenCat,
  } = useMemo(() => {
    const selectedEditions = []
    const selectedAdultCat = []
    const selectedChildrenCat = []
    const activePrices = { under: false, between: false, above: false }

    filters.active.forEach(({ id, type, title }) => {
      if (id === 'underPrice') activePrices.under = true
      if (id === 'betweenPrice') activePrices.between = true
      if (id === 'abovePrice') activePrices.above = true
      if (execSome(ADULT_CATEGORIES, (cat) => cat.id === id))
        selectedAdultCat.push(title)
      if (execSome(CHILDREN_CATEGORIES, (cat) => cat.id === id))
        selectedChildrenCat.push(title)
      if (type === FILTER_TYPE.EDITION) selectedEditions.push(title)
    })

    return {
      selectedEditions,
      activePrices,
      selectedAdultCat,
      selectedChildrenCat,
    }
  }, [filters.active])

  const underPriceLabel = `${settings.underPriceLabel} ${getCurrencySymbol(
    settings?.village?.currency,
    underPrice
  )}`

  const abovePriceLabel = `${settings.abovePriceLabel} ${getCurrencySymbol(
    settings?.village?.currency,
    abovePrice
  )}`

  const betweenPriceLabel = `${settings.betweenPriceLabel} ${getCurrencySymbol(
    settings?.village?.currency,
    underPrice
  )} - ${getCurrencySymbol(settings?.village?.currency, abovePrice)}`

  const refineByCatHeading = () => {
    if (selectedAdultCat.length < 1 && selectedChildrenCat.length < 1) {
      return settings?.refineCategoryLabel
    }

    const adultCategory = getAccordionHeaderLabel(
      selectedAdultCat,
      '',
      settings
    )
    const childrenCategory = getAccordionHeaderLabel(
      selectedChildrenCat,
      '',
      settings
    )

    return adultCategory && childrenCategory
      ? `${adultCategory}, ${childrenCategory}`
      : adultCategory || childrenCategory || ''
  }

  return (
    <FIScrollListener>
      <UpScrollListener>
        <FIAnimatedDisplayContainer>
          <FloatingContainer
            bg={settings?.floatingIslandBg && `#${settings.floatingIslandBg}`}>
            <IslandRow
              noWrap
              extraStyle={`
          align-items: stretch;
          width: 100%;
        `}>
              <IslandRow
                noWrap
                extraStyle={`
              column-gap: ${rem(12)};
              max-width: 75%;
              justify-content: flex-start;
            `}>
                <OutlinedButton onClick={filters.clearAllFilters}>
                  {settings?.viewAllLabel}
                </OutlinedButton>
                {filters.active.length > 0 && (
                  <PhantonButton
                    extraStyle={`
                      font-size: ${rem(16)};
                      text-decoration: underline;
                      max-width: 60%;
                      display: inline-block;
                    `}
                    onClick={onActivePricesClick(
                      filters.active,
                      settings.setDisplayCategories,
                      settings.setDisplayPrices
                    )}>
                    {getActiveFiltersLabel(filters.active, settings)}
                  </PhantonButton>
                )}
              </IslandRow>
              <IslandRowGroup>
                <IconButton
                  {...buttonStyleProps}
                  onClick={settings.toggleDisplayCategories}>
                  <FilterIcon width={rem(14)} />
                </IconButton>
                <IconButton
                  {...buttonStyleProps}
                  onClick={settings.toggleDisplayPrices}
                  style={{ alignItems: 'baseline' }}>
                  {getCurrencySymbol(settings?.village?.currency)}
                </IconButton>
              </IslandRowGroup>
            </IslandRow>
          </FloatingContainer>

          <BottomPanel
            open={settings.islandState.visible && settings.displayCategories}
            bg={settings?.categoryFilterMobileBg}>
            <IslandRow
              noWrap
              extraStyle={`
            align-items: center;
            padding-bottom: ${rem(18)};
          `}>
              <PhantonButton
                {...buttonStyleProps}
                extraStyle={`
              text-decoration: underline;
              font-size: ${rem(15)};
            `}
                onClick={settings.toggleDisplayCategories}>
                {settings?.seeProductsLabel}
              </PhantonButton>
              <PhantonButton
                {...buttonStyleProps}
                onClick={settings.toggleDisplayCategories}>
                <CloseIcon width={rem(24)} />
              </PhantonButton>
            </IslandRow>
            <BottomPanelContent>
              <BottomPanelSection>
                <Accordion
                  delay={editions.length * 0.07}
                  header={(open, onClick) => (
                    <BottomPanelHeader
                      {...buttonStyleProps}
                      active={open}
                      onClick={onClick}>
                      <CategoryBtnText>
                        {getAccordionHeaderLabel(
                          selectedEditions,
                          settings?.refineEditLabel,
                          settings
                        )}
                      </CategoryBtnText>
                      <ChevronIcon active={open} width={rem(14)} />
                    </BottomPanelHeader>
                  )}
                  content={(open) => (
                    <>
                      {editions.map((edition, index) => {
                        const isActive = filters.active.some(
                          ({ id }) => id === edition.id
                        )
                        const itemSlug = isEmpty(edition.slug)
                          ? edition.contentful_id
                          : edition.slug
                        return (
                          <BottomPanelItem
                            {...buttonStyleProps}
                            open={open}
                            inDelay={index * 0.07}
                            outDelay={(editions.length - 1 - index) * 0.05}
                            key={edition.id}
                            active={isActive}
                            onClick={() => {
                              filters.onSelectFilter({
                                filterObj: {
                                  id: edition.id,
                                  type: FILTER_TYPE.EDITION,
                                  title: edition.title,
                                  filter: (product) =>
                                    filterByEdition(
                                      product,
                                      edition.id,
                                      filters.editionProductLink
                                    ),
                                },
                                urlResolver: () => {
                                  updateHashUrl(
                                    handleHashSlugChange(
                                      filters.location.hash,
                                      (hashSection) =>
                                        `${hashSection},${itemSlug}`,
                                      SLUG_TYPES.CATEGORIES,
                                      itemSlug
                                    )
                                  )
                                },
                              })
                            }}>
                            <CategoryBtnText>{edition.title}</CategoryBtnText>
                            {isActive && <CheckIcon width="16px" />}
                          </BottomPanelItem>
                        )
                      })}
                    </>
                  )}
                />
              </BottomPanelSection>

              <BottomPanelSection>
                <Accordion
                  delay={adultCategories.length * 0.12}
                  header={(open, onClick) => (
                    <BottomPanelHeader
                      {...buttonStyleProps}
                      active={open}
                      onClick={onClick}>
                      <CategoryBtnText>{refineByCatHeading()}</CategoryBtnText>
                      <ChevronIcon active={open} width={rem(14)} />
                    </BottomPanelHeader>
                  )}
                  content={(open) => (
                    <>
                      {adultCategories.map(
                        ({ id, genderId, labelId, defaultLabel }, index) => {
                          const isActive = filters.active.some(
                            (filter) => filter.id === id
                          )
                          const categoryLabel =
                            settings[labelId] ?? defaultLabel
                          return (
                            <BottomPanelItem
                              {...buttonStyleProps}
                              open={open}
                              key={id}
                              inDelay={index * 0.12}
                              outDelay={
                                (adultCategories.length - 1 - index) * 0.05
                              }
                              active={isActive}
                              onClick={() => {
                                filters.onSelectFilter({
                                  filterObj: {
                                    id,
                                    type: FILTER_TYPE.CATEGORY,
                                    title: categoryLabel,
                                    filter: (product) =>
                                      filterByAdultGender(product, genderId),
                                  },
                                  beforeAdd: (currentFilters) => {
                                    return execFilter(
                                      currentFilters,
                                      (filterObj) =>
                                        execSome(ADULT_CATEGORIES, (cat) =>
                                          isSubstring(
                                            filterObj.id,
                                            cat.genderId
                                          )
                                        )
                                    )
                                  },
                                  urlResolver: () => {
                                    updateHashUrl(
                                      handleHashSlugChange(
                                        filters.location.hash,
                                        (hashSection) =>
                                          hashSection.replace(
                                            dynamicRegex('=(.+)$', 'gi'),
                                            `=${genderId}`
                                          ),
                                        SLUG_TYPES.ADULT,
                                        genderId
                                      )
                                    )
                                  },
                                })
                              }}>
                              <CategoryBtnText>{categoryLabel}</CategoryBtnText>
                              {isActive && <CheckIcon width="16px" />}
                            </BottomPanelItem>
                          )
                        }
                      )}
                      <BottomPanelItem
                        {...buttonStyleProps}
                        separatorBorder
                        open={open}
                        inDelay={editions.length * 0.02}
                        outDelay={editions.length * 0.01}
                        onClick={() =>
                          filters.clearCategories(
                            ADULT_CATEGORIES,
                            SLUG_TYPES.ADULT
                          )
                        }>
                        <CategoryBtnText>
                          {settings?.viewAllLabel ?? 'View all'}
                        </CategoryBtnText>
                      </BottomPanelItem>
                      {childrenCategories.map(
                        ({ id, genderId, labelId, defaultLabel }, index) => {
                          const isActive = filters.active.some(
                            (filter) => filter.id === id
                          )
                          const categoryLabel =
                            settings[labelId] ?? defaultLabel
                          return (
                            <BottomPanelItem
                              {...buttonStyleProps}
                              open={open}
                              key={id}
                              inDelay={index * 0.2}
                              outDelay={
                                (childrenCategories.length - 1 - index) * 0.15
                              }
                              active={isActive}
                              onClick={() => {
                                filters.onSelectFilter({
                                  filterObj: {
                                    id,
                                    type: FILTER_TYPE.CATEGORY,
                                    title: categoryLabel,
                                    filter: (product) =>
                                      filterByChildrenGender(product, genderId),
                                  },
                                  beforeAdd: (currentFilters) => {
                                    return execFilter(
                                      currentFilters,
                                      (filterObj) =>
                                        execSome(CHILDREN_CATEGORIES, (cat) =>
                                          isSubstring(
                                            filterObj.id,
                                            cat.genderId
                                          )
                                        )
                                    )
                                  },
                                  urlResolver: () => {
                                    updateHashUrl(
                                      handleHashSlugChange(
                                        filters.location.hash,
                                        (hashSection) =>
                                          hashSection.replace(
                                            dynamicRegex('=(.+)$', 'gi'),
                                            `=${genderId}`
                                          ),
                                        SLUG_TYPES.CHILDREN,
                                        genderId
                                      )
                                    )
                                  },
                                })
                              }}>
                              <CategoryBtnText>{categoryLabel}</CategoryBtnText>
                              {isActive && <CheckIcon width="16px" />}
                            </BottomPanelItem>
                          )
                        }
                      )}
                      <BottomPanelItem
                        {...buttonStyleProps}
                        open={open}
                        inDelay={editions.length * 0.03}
                        outDelay={editions.length * 0.01}
                        onClick={() =>
                          filters.clearCategories(
                            CHILDREN_CATEGORIES,
                            SLUG_TYPES.CHILDREN
                          )
                        }>
                        <CategoryBtnText>
                          {settings?.viewAllLabel ?? 'View all'}
                        </CategoryBtnText>
                      </BottomPanelItem>
                    </>
                  )}
                />
              </BottomPanelSection>
            </BottomPanelContent>
            <IslandRow
              noWrap
              extraStyle={`
            padding-top: ${rem(18)};
            justify-content: center;
          `}>
              <PhantonButton
                onClick={filters.clearAllFilters}
                extraStyle={`
              text-decoration: underline;
              ${settings?.fiLinkTextColor &&
                `color: #${settings?.fiLinkTextColor};`}
            `}>
                {settings?.clearLabel ?? 'Clear'}
              </PhantonButton>
            </IslandRow>
          </BottomPanel>

          <BottomPanel
            open={settings.islandState.visible && settings.displayPrices}
            bg={settings?.categoryFilterMobileBg}>
            <IslandRow
              noWrap
              extraStyle={`
            align-items: center;
            padding-bottom: ${rem(18)};
          `}>
              <PhantonButton
                {...buttonStyleProps}
                extraStyle={`
              text-decoration: underline;
              font-size: ${rem(15)};
            `}
                onClick={settings.toggleDisplayPrices}>
                {settings?.seeProductsLabel}
              </PhantonButton>
              <PhantonButton
                {...buttonStyleProps}
                onClick={settings.toggleDisplayPrices}>
                <CloseIcon width={rem(24)} />
              </PhantonButton>
            </IslandRow>
            <BottomPanelContent>
              <BottomPanelSection>
                <CategoryButton
                  title={underPriceLabel}
                  active={activePrices.under}
                  extraStyle="margin-top: initial;"
                  onClick={() => {
                    filters.onSelectFilter({
                      filterObj: {
                        id: PRICE_TYPE.UNDER,
                        type: FILTER_TYPE.PRICE,
                        title: underPriceLabel,
                        filter: (product) =>
                          filterByUnderPrice(product, underPrice),
                      },
                      urlResolver: () => {
                        updateHashUrl(
                          handleHashSlugChange(
                            filters.location.hash,
                            (hashSection) => hashSection,
                            PRICE_TYPE.UNDER,
                            underPrice
                          )
                        )
                      },
                    })
                  }}>
                  <CategoryBtnText>{underPriceLabel}</CategoryBtnText>
                  {activePrices.under && <CheckIcon width={rem(14)} />}
                </CategoryButton>
              </BottomPanelSection>
              <BottomPanelSection>
                <CategoryButton
                  title={betweenPriceLabel}
                  active={activePrices.between}
                  onClick={() => {
                    filters.onSelectFilter({
                      filterObj: {
                        id: PRICE_TYPE.BETWEEN,
                        type: FILTER_TYPE.PRICE,
                        title: betweenPriceLabel,
                        filter: (product) =>
                          filterByBetweenPrices(
                            product,
                            underPrice,
                            abovePrice
                          ),
                      },
                      urlResolver: () => {
                        updateHashUrl(
                          handleHashSlugChange(
                            filters.location.hash,
                            (hashSection) => hashSection,
                            PRICE_TYPE.BETWEEN,
                            `${underPrice}-${abovePrice}`
                          )
                        )
                      },
                    })
                  }}>
                  <CategoryBtnText>{betweenPriceLabel}</CategoryBtnText>
                  {activePrices.between && <CheckIcon width={rem(14)} />}
                </CategoryButton>
              </BottomPanelSection>
              <BottomPanelSection>
                <CategoryButton
                  title={abovePriceLabel}
                  active={activePrices.above}
                  onClick={() => {
                    filters.onSelectFilter({
                      filterObj: {
                        id: PRICE_TYPE.ABOVE,
                        type: FILTER_TYPE.PRICE,
                        title: abovePriceLabel,
                        filter: (product) =>
                          filterByAbovePrice(product, abovePrice),
                      },
                      urlResolver: () => {
                        updateHashUrl(
                          handleHashSlugChange(
                            filters.location.hash,
                            (hashSection) => hashSection,
                            PRICE_TYPE.ABOVE,
                            abovePrice
                          )
                        )
                      },
                    })
                  }}>
                  <CategoryBtnText>{abovePriceLabel}</CategoryBtnText>
                  {activePrices.above && <CheckIcon width={rem(14)} />}
                </CategoryButton>
              </BottomPanelSection>
              <BottomPanelSection>
                <CategoryButton
                  title={settings?.viewAllLabel}
                  onClick={filters.clearPrices}>
                  <CategoryBtnText>{settings?.viewAllLabel}</CategoryBtnText>
                </CategoryButton>
              </BottomPanelSection>
            </BottomPanelContent>
            <IslandRow
              noWrap
              extraStyle={`padding-top: ${rem(16)}; justify-content: center;`}>
              <PhantonButton
                onClick={filters.clearAllFilters}
                extraStyle={`
              text-decoration: underline;
              ${settings?.fiLinkTextColor &&
                `color: #${settings?.fiLinkTextColor};`}
            `}>
                {settings?.clearLabel ?? 'Clear'}
              </PhantonButton>
            </IslandRow>
          </BottomPanel>
        </FIAnimatedDisplayContainer>
      </UpScrollListener>
    </FIScrollListener>
  )
}

export default PGFIMobile
