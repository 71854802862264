import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { contextDefinition as contentContext } from 'contexts/giftGuide/content'
import { contextDefinition as settingsContext } from 'contexts/giftGuide/settings'
import {
  ACTION_ISLAND_DISABLE,
  ACTION_ISLAND_ENABLE,
} from 'contexts/giftGuide/settings/constants'
import {
  contextDefinition as filterContext,
  filterProducts,
  execSome,
  execFilter,
} from 'contexts/giftGuide/filters'
import { scrollIntoView } from 'hooks/useElementVisibility'
import NewGiftGuideProductCard from 'components/NewGiftGuideProductCard/NewGiftGuideProductCard'
import PromoGiftCard from 'components/NewGiftGuideProductCard/PromoGiftCard'
import PromoCrossSelling from 'components/NewGiftGuideProductCard/PromoCrossSelling'
import useOfferCardDetail from 'components/NewOfferGrid/useOfferCardDetail'
import NewGiftGuideProductCardDetails from 'components/NewOfferCardDetail/NewGiftGuideProductCardDetails'
import NewGiftGuideProductCardDetailsMobile from 'components/NewOfferCardDetail/mobile/NewGiftGuideProductCardDetailsMobile'
import { GridSection, GridContainer } from './GiftGuideGrid.style'

const GiftGuideGrid = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const PROMO_CARD_INTERVAL = 4
  let indexCrossSellingCard = 0

  const settings = useContext(settingsContext)
  const filters = useContext(filterContext)
  const { products, promoCrossSellingCards, promoGiftCards } = useContext(
    contentContext
  )

  const availablePromoCards = execFilter(
    promoCrossSellingCards,
    ({ id }) => !execSome(filters.active, (filter) => filter.id === id)
  )

  const displayProducts =
    useMemo(() => {
      if (filters.active.length < 1) return products

      return filterProducts(products, filters.active)
    }, [filters.active]) ?? []

  const {
    isCardSelected,
    selectedOffer,
    showBigCard,
    gridRow,
    setIsCardSelected,
    setIndexSelected,
    close,
  } = useOfferCardDetail(displayProducts, isMobile)

  const onIndexSelected = (index) => {
    setIndexSelected(index)
    settings.dispatchIslandAction({ type: ACTION_ISLAND_DISABLE })
  }

  const onCloseDetails = () => {
    close()
    settings.dispatchIslandAction({ type: ACTION_ISLAND_ENABLE })
  }

  useLayoutEffect(() => {
    scrollIntoView('gift-guide-grid')
  }, [displayProducts.length])

  useEffect(() => {
    if (displayProducts.length > 0) {
      setIsLoaded(true)
    }
  }, [])

  return (
    <>
      {!isLoaded ? (
        <div>
          <p>loading...</p>
        </div>
      ) : (
        <GridSection>
          <GridContainer showFullGrid id="gift-guide-grid">
            {displayProducts.map((item, index) => {
              const columnIndex = index % (isMobile ? 2 : 4)

              const productPosition = index + 1
              let showPromoCard = false
              if (productPosition % PROMO_CARD_INTERVAL === 0) {
                showPromoCard = true
              }

              let isGiftCard = false
              if (showPromoCard) {
                const promoCardNumber = Math.floor(
                  productPosition / PROMO_CARD_INTERVAL
                )
                if (promoCardNumber % 2 === 0) {
                  isGiftCard = true
                } else {
                  isGiftCard = false
                  indexCrossSellingCard += 1
                }
              }

              return (
                <>
                  <NewGiftGuideProductCard
                    index={index}
                    brand={item.brand}
                    media={item.image}
                    showFullGrid
                    columnIndex={columnIndex}
                    isModalOpen={selectedOffer && selectedOffer.id === item.id}
                    setIndexSelected={onIndexSelected}
                    setIsCardSelected={setIsCardSelected}
                    isCardSelected={isCardSelected}
                  />
                  {showPromoCard && isGiftCard ? (
                    <PromoGiftCard
                      type="gift-card"
                      isCardSelected={isCardSelected}
                      {...promoGiftCards}
                    />
                  ) : null}
                  {showPromoCard &&
                  !isGiftCard &&
                  availablePromoCards.length >= indexCrossSellingCard ? (
                    <PromoCrossSelling
                      type="cross-selling"
                      isCardSelected={isCardSelected}
                      {...availablePromoCards[indexCrossSellingCard - 1]}
                    />
                  ) : null}
                </>
              )
            })}

            {showBigCard && (
              <NewGiftGuideProductCardDetailsMobile
                gridRow={gridRow}
                settings={settings}
                village={settings.village}
                nodeLocale={settings.nodeLocale}
                close={onCloseDetails}
                {...selectedOffer}
              />
            )}

            {isCardSelected && !isMobile && (
              <NewGiftGuideProductCardDetails
                isModalOpen={selectedOffer && isCardSelected}
                close={onCloseDetails}
                nodeLocale={settings.nodeLocale}
                settings={settings}
                village={settings.village}
                {...selectedOffer}
              />
            )}
          </GridContainer>
        </GridSection>
      )}
    </>
  )
}

export default GiftGuideGrid
