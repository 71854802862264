import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Content } from './style'

const Accordion = ({ content, delay, header }) => {
  const [open, setOpen] = useState(false)
  const accordionContentRef = useRef(null)
  const accordionMaxHeight = useRef(0)

  const toggleOpen = () =>
    setOpen((state) => {
      if (state) {
        accordionMaxHeight.current = 0
        return false
      }

      accordionMaxHeight.current =
        accordionContentRef.current?.scrollHeight ?? 0
      return true
    })

  return (
    <Wrapper>
      {header(open, toggleOpen)}
      <Content
        ref={accordionContentRef}
        open={open}
        delay={delay}
        style={{
          maxHeight: `${accordionMaxHeight.current}px`,
        }}>
        {content(open)}
      </Content>
    </Wrapper>
  )
}

Accordion.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.node, null]),
  delay: PropTypes.number,
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.node, null]),
}

Accordion.defaultProps = {
  content: null,
  delay: null,
  header: null,
}

export default Accordion
