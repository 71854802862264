import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import {
  checkExternalUrl,
  normalizeExternalUrl,
  normalizeInternalUrl,
} from 'utils/urlUtils'
import { contextDefinition as settingsContext } from 'contexts/giftGuide/settings'
import {
  GiftGuideCardContainer,
  GiftGuideCardWrapper,
  GiftGuideCardImg,
  GiftCardButton,
  ButtonWrapper,
} from './NewGiftGuideProductCard.style'

const PromoGiftCard = ({
  type,
  index,
  isModalOpen,
  columnIndex,
  isCardSelected,
}) => {
  const settings = useContext(settingsContext)

  const onGiftCardClick = (to) => {
    if (checkExternalUrl(to)) {
      window.location.href = normalizeExternalUrl(to)
      return
    }
    navigate(
      `/${settings.village.villageSlug}/${
        settings.nodeLocale
      }${normalizeInternalUrl(to ?? '#')}`
    )
  }

  return (
    <GiftGuideCardContainer
      columnIndex={columnIndex}
      id={`promo-${type}-${index}`}
      selected={isModalOpen}
      isCardSelected={isCardSelected}>
      <GiftGuideCardWrapper>
        <GiftGuideCardImg
          selected={isModalOpen}
          cardColor={settings.productCardOverlayColor}
          image={settings.servicePromoCardImage?.portrait?.gatsbyImageData}
          alt={settings.servicePromoCardImage?.altText ?? 'offer-image'}
        />
        <ButtonWrapper>
          <GiftCardButton
            backgroundColour={settings.primaryBg}
            onClick={() => onGiftCardClick(settings.servicePromoCardCtaUrl)}>
            {settings.servicePromoCardCtaLabel}
          </GiftCardButton>
        </ButtonWrapper>
      </GiftGuideCardWrapper>
    </GiftGuideCardContainer>
  )
}

PromoGiftCard.propTypes = {
  index: PropTypes.number,
  type: PropTypes.string,
  isModalOpen: PropTypes.bool,
  columnIndex: PropTypes.number.isRequired,
  isCardSelected: PropTypes.bool,
}

PromoGiftCard.defaultProps = {
  index: 0,
  type: '',
  isModalOpen: false,
  isCardSelected: false,
}

export default PromoGiftCard
