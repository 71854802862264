/* eslint-disable import/prefer-default-export */
import { createContext } from 'react'
import { isMobile } from 'react-device-detect'

export const contextInitialState = {
  heroBg: null,
  floatingIslandBg: null,
  categoryFilterBg: null,
  categoryFilterMobileBg: null,
  primaryBg: null,
  secondaryBg: null,
  nodeLocale: 'en-US',
  village: {},
  viewAllLabel: 'View all',
  clearLabel: 'See products',
  productCardOverlayColor: null,
  productCardTextColor: null,
  productPopupBg: null,
  fiLinkTextColor: null,
  cta1Label: null,
  cta1Url: null,
  cta2Label: null,
  cta2Url: null,
  refineCategoryLabel: 'Refined by category: ',
  refineEditLabel: 'Refined by edit: ',
  categoryAll: 'View all',
  categoryBoys: 'Boys',
  categoryGirls: 'Girls',
  categoryMen: 'Mens',
  categoryWomen: 'Womens',
  seeProductsLabel: 'See products',
  underPriceLabel: 'Under',
  betweenPriceLabel: 'Between',
  abovePriceLabel: 'Above',
  filtersLabel: 'Filters',
  servicePromoCardImage: null,
  servicePromoCardCtaLabel: 'Gift Card',
  servicePromoCardCtaUrl: '/',
  rrpPriceLabel: 'ORP',
  villagePriceLabel: 'Village',
  salePriceLabel: 'Sale',

  // UI SETTINGS
  isMobile,
  selectedProduct: null,
  displayCategories: false,
  displayPrices: false,
  islandState: {
    enabled: true,
    visible: true,
  },
  // UI Methods
  selectProduct: () => null,
  setDisplayCategories: () => null,
  toggleDisplayCategories: () => null,
  setDisplayPrices: () => null,
  toggleDisplayPrices: () => null,
  dispatchIslandAction: (action) => action,
}

export const contextDefinition = createContext(contextInitialState)

/* FLOATING ISLAND REDUCER STRUCTURE */
export const ACTION_ISLAND_DISABLE = 'ACTION_ISLAND_DISABLE'
export const ACTION_ISLAND_ENABLE = 'ACTION_ISLAND_ENABLE'
export const ACTION_ISLAND_HIDE = 'ACTION_ISLAND_HIDE'
export const ACTION_ISLAND_SHOW = 'ACTION_ISLAND_SHOW'

export const islandInitialState = {
  enabled: true,
  visible: true,
}

export function islandReducer(state, action) {
  switch (action.type) {
    case ACTION_ISLAND_DISABLE: {
      return { ...state, enabled: false }
    }
    case ACTION_ISLAND_ENABLE: {
      return { ...state, enabled: true }
    }
    case ACTION_ISLAND_HIDE: {
      if (state.enabled && state.visible) {
        return { ...state, visible: false }
      }
      return state
    }
    case ACTION_ISLAND_SHOW: {
      if (state.enabled && !state.visible) {
        return { ...state, visible: true }
      }
      return state
    }
    default: {
      return state
    }
  }
}
