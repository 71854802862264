import { useEffect } from 'react'

/**
 * Hook that closes the card on click outside of the passed ref
 */
export default (ref, close) => {
  useEffect(() => {
    /**
     * Close if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        close()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
