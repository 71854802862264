import { Body18 } from 'components/Typography'
import { GatsbyImage } from 'gatsby-plugin-image'
import { rem, transitions } from 'polished'
import styled from 'styled-components'
import theme from 'styles/theme'

export const GiftGuideCardContainer = styled.article`
  ${transitions('margin-top 0.2s, transform 1s')}
  background-color: #f0f0f0;
  height: 250px;
  border: 0px solid transparent;
  flex: 1 0 100%;
  position: relative;
  text-align: center;

  width: 100%;
  ${theme.mediaQueries.mobile} {
    &:nth-child(odd) {
      margin-top: ${({ isCardSelected }) =>
        isCardSelected ? '-0px;' : '-61px;'};
    }

    .card-wrap {
      grid-column: 2;
    }
  }
  ${theme.mediaQueries.small} {
    height: 371px;
    width: 100%;
    &:nth-child(odd) {
      margin-top: -80px;
    }
    &:nth-child(1),
    &:nth-child(3) {
      margin-top: -80px;
    }
  }
`

export const GiftGuideCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const GiftGuideCardImg = styled(GatsbyImage)`
  display: block;
  height: 100%;
  width: 100%;

  ::before {
    ${transitions('height 0.4s')}
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${({ selected }) => (selected ? '100%' : '60%')};
    min-height: 10vh;
    z-index: 1;
    opacity: ${({ selected }) => (selected ? '0.5' : '1')};
    background: ${({ cardColor }) =>
      cardColor ??
      'linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111 100%);'};
  }
`

export const GiftGuideCardBody = styled.div`
  color: ${theme.colors.white};
  position: absolute;
  width: 100%;
  bottom: 0%;
  padding: ${rem(8)};
  left: 0;
  text-align: left;
  z-index: 2;

  ${theme.mediaQueries.medium} {
    padding: ${rem(14.3)};
  }
`

export const GiftGuideCardTitle = styled(Body18)`
  font-size: ${rem(16)};
  color: ${(props) =>
    props.color ? `#${props.color}` : theme.colors.sagefeather};
  line-height: initial;
  font-family: BrownStd-Light, serif;
  font-weight: 300;

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(12)};
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  position: absolute;
  justify-content: 'center';
  margin-top: ${rem(-100)};
  padding: ${rem(20)};
  z-index: 99;

  ${theme.mediaQueries.mobile} {
    margin-top: ${rem(-65)};
    padding: ${rem(12)};
  }
`

export const GiftCardButton = styled.button`
  width: 100%;
  padding: ${rem(20)}};
  font-size: ${rem(10)};
  border: 1px solid
    ${(props) =>
      props.backgroundColour
        ? `#${props.backgroundColour}`
        : `${theme.colors.white}`};
  background-color: ${(props) =>
    props.backgroundColour ? `#${props.backgroundColour}` : `transparent`};
  color: ${theme.colors.white};
  text-transform: uppercase;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  ${theme.mediaQueries.medium} {
    font-size: ${rem(18)};
  }

  ${theme.mediaQueries.mobile} {
    padding: ${rem(15)} ${rem(10)}};
    font-size: ${rem(10)};
  }
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  margin-top: -250px;
  padding: ${rem(20)};
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);

  ${theme.mediaQueries.small} {
    margin-top: -371px;
  }

  ${theme.mediaQueries.mobile} {
    padding: ${rem(12)};
  }
`

export const CrossSellingCardTitle = styled(Body18)`
  font-size: ${rem(20)};
  color: ${theme.colors.white};
  line-height: initial;
  font-family: SangbleuKindgdom-Light;
  font-weight: 200;
  font-style: ${(props) => props.fontStyle || 'normal'};
  line-height: 1.5;

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(12)};
  }
`
