import { useEffect, useRef, useState } from 'react'

const DEFAULT_DELAY = 1000
const DEFAULT_INTERVAL = 2000
const DEFAULT_CALLBACK = (param = null) => param

export const debounceFn = (callback, delay = DEFAULT_DELAY) => {
  let timeout = null

  return () => {
    clearTimeout(timeout)
    timeout = setTimeout(callback, delay)
  }
}

export const useDebounce = (value, delay = DEFAULT_DELAY) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(() => value), delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debouncedValue
}

export const useThrottle = (
  callback = DEFAULT_CALLBACK,
  interval = DEFAULT_INTERVAL
) => {
  const lastRun = useRef(Date.now())

  return (forwardParams) => {
    const dtNow = Date.now()
    if (dtNow - lastRun.current >= interval) {
      lastRun.current = dtNow
      callback(forwardParams)
    }
  }
}
