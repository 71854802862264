/* eslint-disable import/prefer-default-export */
import styled, { css, keyframes } from 'styled-components'
import { rem, transitions } from 'polished'
import theme from 'styles/theme'
import { Container } from 'components/FloatingIsland/FloatingIsland.style'
import BaseChevronIcon from 'components/Icons/ChevronIcon'
import { surgeIn, surgeOut, CategoryButton } from '../style'

const itemIn = keyframes`
  from {
    opacity: 0;
    transform: scaleX(0.75) translateY(-25px);
  }
  to {
    opacity: 1;
    transform: scaleX(1) translateY(0);
  }
`

const itemOut = keyframes`
  from {
    opacity: 1;
    transform: scaleX(1) translateY(0);
  }
  99.9% {
    transform: scaleX(0.75) translateY(-50px);
  }
  to {
    opacity: 0;
    transform: scaleX(0.75) translateY(0);
  }
`

export const FloatingContainer = styled(Container)`
  padding: ${rem(16)};
  backdrop-filter: initial;
  box-shadow: initial;
`

export const BottomPanel = styled.div`
  z-index: 5;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  padding: ${rem(26.79)} ${rem(25.81)};
  backdrop-filter: blur(25px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  animation-duration: 0.375s;
  animation-name: ${(props) => (props?.open ? surgeIn : surgeOut)};

  ${(props) => {
    if (!props?.bg) return `background-color: ${theme.colors.gold};`

    if (/(linear)|(gradient)+/gi.test(props.bg))
      return `
      background-image: ${props.bg};
      background-color: rgba(0,0,0,0.1);
    `
    return `background-color: ${props.bg};`
  }}
`

export const BottomPanelContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  flex: 1;
  overflow: auto;
`

export const BottomPanelSection = styled.section`
  width: 100%;
  padding: ${rem(17)} ${rem(15)};

  &:first-child {
    padding-top: initial;
  }
`

export const ChevronIcon = styled(BaseChevronIcon)`
  ${transitions('transform 0.5s')};

  &,
  path {
    stroke: inherit;
    fill: inherit;
  }

  ${(props) =>
    props?.active
      ? `
    transform: rotateZ(90deg);
  `
      : `
    transform: rotateZ(270deg);
  `}
`
export const BottomPanelHeader = styled(CategoryButton)`
  margin-top: initial;
  margin-bottom: initial;

  &,
  span {
    line-height: 1;
  }
`

export const BottomPanelItem = styled(CategoryButton)`
  animation-fill-mode: forwards;
  &,
  span {
    line-height: 1;
  }

  ${(props) =>
    props?.open
      ? css`
          opacity: 0;
          animation-name: ${itemIn};
          animation-duration: 0.2s;
          animation-delay: ${props?.inDelay ?? 0}s;
        `
      : css`
          opacity: 1;
          animation-name: ${itemOut};
          animation-duration: 0.15s;
          animation-delay: ${props?.outDelay ?? 0}s;
          transform: translateY(0);
        `};
`
