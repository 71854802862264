/* eslint-disable import/prefer-default-export */
import styled, { css, keyframes } from 'styled-components'
import { rem } from 'polished'
import theme from 'styles/theme'
import BaseCheckIcon from 'components/Icons/CheckIcon'
import {
  IconButton as BaseIconButton,
  PhatonButton as BasePhantonButton,
} from 'components/FloatingIsland/FloatingIsland.style'

export const surgeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(25%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`

export const surgeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(25%);
  }
`

export const popIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const islandIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const islandOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`

const buttonSharedStyle = css`
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    border-color: transparent;
    background-color: ${(props) => props?.activeBg ?? theme.colors.gold};
    color: ${theme.colors.white};
    stroke: ${theme.colors.white};
    fill: ${theme.colors.white};

    path {
      stroke: ${theme.colors.white};
    }
  }

  &:hover:not(:disabled) {
    border-color: ${(props) => props?.secondaryBg ?? theme.colors.pastelGold};
    background-color: ${(props) =>
      props?.active ? theme.colors.gold : theme.colors.pastelGold};
    color: ${(props) => props?.secondaryBg ?? theme.colors.white};
    stroke: ${(props) => props?.secondaryBg ?? theme.colors.white};
    fill: ${(props) => props?.secondaryBg ?? theme.colors.white};

    path {
      stroke: ${(props) => props?.secondaryBg ?? theme.colors.white};
    }
  }

  ${(props) =>
    props?.active &&
    css`
      background-color: ${props?.activeBg ?? theme.colors.gold};
    `}
`

export const AnimatedContainer = styled.div`
  animation-name: ${islandIn};
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  max-height: calc(100vh - 80px);

  ${(props) =>
    props?.hide &&
    css`
      animation-name: ${islandOut};
    `}
`

export const IconButton = styled(BaseIconButton)`
  ${buttonSharedStyle}
  position: relative;
  border: 1px solid ${theme.colors.white};
  background-color: ${(props) => props?.bg ?? theme.colors.pastelGold};

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: ${(props) => props?.activeBg ?? theme.colors.pastelGold};
    border: 1px solid ${theme.colors.white};
    color: ${theme.colors.white};
    stroke: ${theme.colors.white};

    path {
      stroke: inherit;
    }
  }

  *,
  svg,
  path,
  polygon {
    stroke: inherit;
    fill: transparent;
  }

  ${(props) =>
    props?.fontSize &&
    css`
      & {
        font-size: ${props.fontSize};
      }

      * {
        font-size: ${props.fontSize};
      }
    `}

  ${(props) =>
    props?.active &&
    css`
      background: ${props?.activeBg ?? theme.colors.gold};
      border: 1px solid ${theme.colors.white};
      color: ${theme.colors.white};
      stroke: ${theme.colors.white};
    `}

  &:hover:not(:disabled) {
    background: ${(props) => props?.bg ?? theme.colors.pastelGold};
    border: 1px solid ${theme.colors.white};
    color: ${theme.colors.white};
    stroke: ${theme.colors.white};

    path {
      stroke: inherit;
    }
  }
`

export const PhantonButton = styled(BasePhantonButton)`
  ${buttonSharedStyle}
  font-family: BrownStd-Light, serif;
  padding: initial;
  fill: ${theme.colors.white};
  stroke: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    margin-bottom: initial;
  }

  &:hover,
  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    background: none;
    box-shadow: none;
    color: ${(props) => props?.bg ?? theme.colors.pastelGold};
    fill: ${(props) => props?.activeBg ?? theme.colors.gold};
    stroke: initial;
  }

  path,
  polygon {
    fill: inherit;
    stroke: inherit;
  }
`

export const OutlinedButton = styled(BasePhantonButton)`
  ${buttonSharedStyle}
  border: 1px solid ${theme.colors.white};
  text-transform: uppercase;
  font-family: BrownStd-Light, serif;
  white-space: nowrap;

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    z-index: 1;
    box-shadow: none;
    border-color: ${theme.colors.white};
  }

  &:hover:not(:disabled) {
    z-index: 1;
    box-shadow: none;
    border-color: ${theme.colors.white};
  }

  ${theme.mediaQueries.medium} {
    font-size: ${rem(14)};
  }
`
export const CloseIconButton = styled.div`
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.coallight};
  }
`

export const CategoryButton = styled(OutlinedButton)`
  padding: ${rem(17)} ${rem(21)};
  width: 100%;
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
  align-items: strech;
  margin-top: -1px;
  position: relative;
  background: ${(props) =>
    props.active ? theme.colors.gold : props?.bg ?? theme.colors.pastelGold};
  text-transform: initial;
  color: ${theme.colors.white};
  stroke: transparent;
  fill: ${theme.colors.white};

  ${(props) => (props.separatorBorder ? `border-bottom-width: 6px;` : '')}

  &,
  * {
    font-family: BrownStd-Light, serif;
    font-size: ${rem(16)};
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    z-index: 1;
    box-shadow: none;
    border-color: ${theme.colors.white};
  }

  span:first-child {
    display: block;
    text-align: left;
  }

  &:hover:not(:disabled) {
    z-index: 1;
    box-shadow: none;
    border-color: ${theme.colors.white};
  }

  * path,
  * polygon {
    stroke: inherit;
    fill: inherit;
  }

  ${(props) =>
    props?.extraStyle &&
    css`
      ${props.extraStyle}
    `}
`

export const CategoryBtnText = styled.span`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const CheckIcon = styled(BaseCheckIcon)`
  animation: ${popIn} 0.5s 1;
  min-width: ${(props) => props?.width ?? rem(14)};
`
