import React from 'react'
import Layout from 'components/Layout'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import ContentContext from 'contexts/giftGuide/content'
import FitlersContext from 'contexts/giftGuide/filters'
import SettingsContext from 'contexts/giftGuide/settings'
import GiftGuidesHero from 'components/GiftGuidesHeroSimple/GiftGuidesHeroSimple'
import GiftGuideFloatingIsland from 'components/GiftGuideFloatingIsland'
import GiftGuideGrid from 'components/GiftGuideGrid/GiftGuideGrid'
import { Section } from 'components/GiftGuideGrid/GiftGuideGrid.style'

const FilteredProductGuidePageTemplate19 = (props) => {
  const { data, pageContext, location } = props
  const {
    page,
    labels: {
      edges: [{ node: labels }],
    },
  } = data

  const {
    jsonLdSchema,
    content,
    underPrice,
    abovePrice,
    hero,
    heroBg,
    title,
    description,
  } = page

  const settings = {
    nodeLocale: pageContext.nodeLocale,
    floatingIslandBg: page.floatingIslandBg,
    categoryFilterBg: page.categoryFilterBg,
    categoryFilterMobileBg: page.categoryFilterMobileBg,
    productCardOverlayColor: page.productCardOverlayColor,
    productCardTextColor: page.productCardTextColor,
    productPopupBg: page.productPopupBg,
    primaryBg: page.primaryBg,
    secondaryBg: page.secondaryBg,
    cta1Label: page.cta1Label,
    cta1Url: page.cta1Url,
    cta2Label: page.cta2Label,
    cta2Url: page.cta2Url,
    fiLinkTextColor: page.fiLinkTextColor,
    village: page.village,
    servicePromoCardImage: page.servicePromoCardImage,
    servicePromoCardCtaLabel: page.servicePromoCardCtaLabel,
    servicePromoCardCtaUrl: page.servicePromoCardCtaUrl,
    ...labels,
  }

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema?.jsonLdSchema?.length > 5 && (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        )}
      </Helmet>
      <SettingsContext settings={settings}>
        <ContentContext
          content={content}
          underPrice={underPrice}
          abovePrice={abovePrice}>
          <FitlersContext content={content} location={location}>
            <GiftGuidesHero
              {...hero}
              hexValue={heroBg}
              title={title}
              description={description}
            />
            <Section>
              <GiftGuideFloatingIsland />
              <GiftGuideGrid />
            </Section>
          </FitlersContext>
        </ContentContext>
      </SettingsContext>
    </Layout>
  )
}

FilteredProductGuidePageTemplate19.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.shape({
    nodeLocale: PropTypes.string.isRequired,
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export const filteredProductGuidePageTemplate = graphql`
  query(
    $id: String!
    $openingHoursId: String
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
  ) {
    page: contentfulPageTemplateFilteredProductGuideT19(id: { eq: $id }) {
      id
      contentful_id
      instanceName
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        openingHours {
          ...villageOpeningHours
        }
        openingStatusLabel
        labels: label_brand_details_lab01 {
          closedLabel
          closingLabel
          openingLabel
          reopeningLabel
        }
        defaultLocale
        villagePlaceholderImage: image {
          __typename
          ... on Node {
            ... on ContentfulEntityImageEnt02 {
              ...ResponsiveImageQuery
            }
          }
        }
      }
      title
      description
      pageTitle
      pageDescription
      hideFromSearchEngine
      jsonLdSchema {
        jsonLdSchema
      }
      hero {
        ...heroMediaQuery
        eyebrow
        headline
      }
      content {
        ...giftGuideCat2
      }
      underPrice
      abovePrice
      heroBg
      floatingIslandBg
      categoryFilterBg
      categoryFilterMobileBg
      productCardOverlayColor
      productCardTextColor
      productPopupBg
      primaryBg
      secondaryBg
      fiLinkTextColor
      cta1Label
      cta1Url
      cta2Label
      cta2Url
      servicePromoCardImage {
        __typename
        ...ResponsiveSingleImageQuery
      }
      servicePromoCardCtaLabel
      servicePromoCardCtaUrl
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    labels: allContentfulLabelsProductGuideTemplate(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          id
          categoryAll
          categoryBoys
          categoryGirls
          categoryMen
          categoryWomen
          clearLabel
          node_locale
          refineCategoryLabel
          refineEditLabel
          refinePriceLabel
          viewAllLabel
          seeProductsLabel
          underPriceLabel
          betweenPriceLabel
          abovePriceLabel
          filtersLabel
          rrpPriceLabel
          villagePriceLabel
          salePriceLabel
        }
      }
    }
  }
`

export default FilteredProductGuidePageTemplate19
