import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import useScrollListener, { SCROLL_DIRECTIONS } from 'hooks/useScrollListener'
import { contextDefinition as settingsContext } from 'contexts/giftGuide/settings'
import {
  ACTION_ISLAND_HIDE,
  ACTION_ISLAND_SHOW,
} from 'contexts/giftGuide/settings/constants'

const delayConfig = {
  scroll: 200,
  iddle: 750,
}

const UpScrollListener = ({ children }) => {
  const { dispatchIslandAction } = useContext(settingsContext)

  const onIddle = () => dispatchIslandAction({ type: ACTION_ISLAND_SHOW })

  const onScroll = (_, { scrollDirection }) => {
    if (scrollDirection === SCROLL_DIRECTIONS.up) {
      dispatchIslandAction({ type: ACTION_ISLAND_HIDE })
    }
  }

  useScrollListener({
    delayAmount: delayConfig,
    onIddle,
    onScroll,
  })

  useScrollListener({
    delayAmount: delayConfig,
    onIddle,
    onScroll,
    scrollEventName: 'scroll',
  })

  return <>{children}</>
}

UpScrollListener.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
    null,
  ]),
}

UpScrollListener.defaultProps = {
  children: null,
}

export default UpScrollListener
