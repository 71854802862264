/* eslint-disable camelcase */
import { getCurrencySymbol } from 'components/GiftGuideFloatingIsland/utils'

import { ADULT_CATEGORIES, CHILDREN_CATEGORIES } from '../content/constants'

import {
  FILTER_TYPE,
  PRICE_TYPE,
  SLUG_TYPES,
  isEmpty,
  execFind,
  filterByEdition,
  filterByAdultGender,
  filterByChildrenGender,
  filterByUnderPrice,
  filterByBetweenPrices,
  filterByAbovePrice,
} from './constants'

const parseHashSection = (hashSection, result) => {
  const [hashId, hashValues] = hashSection.split('=')

  if (isEmpty(hashId) || isEmpty(hashValues)) return result

  const values = hashValues.split(',').filter((value) => !isEmpty(value))

  if (isEmpty(values)) return result

  result.push([hashId, values])

  return result
}

const recursiveParse = (hashArray, result = []) => {
  if (hashArray.length <= 1) {
    return parseHashSection(hashArray[0], result)
  }

  return recursiveParse(
    hashArray,
    parseHashSection(hashArray.splice(0, 1)[0], result)
  )
}

const parseHash = (rawHasg) => {
  return recursiveParse(rawHasg.split('#')[1].split('&'))
}

const handleCategories = ({
  content,
  editionProductLink,
  preLoadedFilters,
  categorySlug,
}) => {
  const editionFound = execFind(content, ({ slug, contentful_id }) => {
    if (isEmpty(slug)) return categorySlug === contentful_id
    return slug === categorySlug || categorySlug === contentful_id
  })

  if (isEmpty(editionFound)) return preLoadedFilters

  preLoadedFilters.push({
    id: editionFound.id,
    type: FILTER_TYPE.EDITION,
    title: editionFound.title,
    filter: (product) =>
      filterByEdition(product, editionFound.id, editionProductLink),
  })

  return preLoadedFilters
}

const handleAdultGender = ({ id, preLoadedFilters }) => {
  const categoryFound = ADULT_CATEGORIES.find((cateogry) =>
    new RegExp(cateogry.genderId, 'gi').test(id)
  )
  if (categoryFound) {
    preLoadedFilters.push({
      id: categoryFound.id,
      type: FILTER_TYPE.CATEGORY,
      title: categoryFound.defaultLabel,
      filter: (product) => filterByAdultGender(product, categoryFound.genderId),
    })
  }
}

const handleChildrenGender = ({ id, preLoadedFilters }) => {
  const categoryFound = CHILDREN_CATEGORIES.find((cateogry) =>
    new RegExp(cateogry.genderId, 'gi').test(id)
  )
  if (categoryFound) {
    preLoadedFilters.push({
      id: categoryFound.id,
      type: FILTER_TYPE.CATEGORY,
      title: categoryFound.defaultLabel,
      filter: (product) =>
        filterByChildrenGender(product, categoryFound.genderId),
    })
  }
}

const handleUnderPrice = ({ settings, preLoadedFilters, price }) => {
  preLoadedFilters.push({
    id: PRICE_TYPE.UNDER,
    type: FILTER_TYPE.PRICE,
    title: `${settings.underPriceLabel}: ${getCurrencySymbol(
      settings.village.currency,
      price
    )}`,
    filter: (product) => filterByUnderPrice(product, price),
  })
}

const handleBetweenPrices = ({ settings, preLoadedFilters, price }) => {
  const [minPrice, maxPrice] = price.split('-')
  if (minPrice && maxPrice) {
    preLoadedFilters.push({
      id: PRICE_TYPE.BETWEEN,
      type: FILTER_TYPE.PRICE,
      title: `${settings.betweenPriceLabel}: ${getCurrencySymbol(
        settings.village.currency,
        minPrice
      )}-${getCurrencySymbol(settings.village.currency, maxPrice)}`,
      filter: (product) =>
        filterByBetweenPrices(product, Number(minPrice), Number(maxPrice)),
    })
  }
}

const handleAbovePrice = ({ settings, preLoadedFilters, price }) => {
  preLoadedFilters.push({
    id: PRICE_TYPE.ABOVE,
    type: FILTER_TYPE.PRICE,
    title: `${settings.abovePriceLabel} ${getCurrencySymbol(
      settings.village.currency,
      price
    )}`,
    filter: (product) => filterByAbovePrice(product, price),
  })
}

const RESOLVERS = {
  [SLUG_TYPES.CATEGORIES]: (tools, value) =>
    handleCategories({ ...tools, categorySlug: value }),
  [SLUG_TYPES.ADULT]: (tools, value) =>
    handleAdultGender({ ...tools, id: value }),
  [SLUG_TYPES.CHILDREN]: (tools, value) =>
    handleChildrenGender({ ...tools, id: value }),
  [SLUG_TYPES.UNDER]: (tools, value) =>
    handleUnderPrice({ ...tools, price: value }),
  [SLUG_TYPES.BETWEEN]: (tools, value) =>
    handleBetweenPrices({ ...tools, price: value }),
  [SLUG_TYPES.ABOVE]: (tools, value) =>
    handleAbovePrice({ ...tools, price: value }),
}

export default function fillPreloadedFilters({
  settings,
  content,
  editionProductLink,
  urlHash,
}) {
  const parsedHashArray = parseHash(urlHash)

  const preLoadedFilters = []

  if (isEmpty(parsedHashArray)) return preLoadedFilters

  parsedHashArray.forEach(([hashId, hashValues]) => {
    const resolverFunc = RESOLVERS[hashId]

    if (isEmpty(resolverFunc)) return null

    hashValues.forEach((hashValue) => {
      resolverFunc(
        {
          settings,
          content,
          editionProductLink,
          preLoadedFilters,
        },
        hashValue
      )
    })

    return null
  })

  return preLoadedFilters
}
