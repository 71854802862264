import styled from 'styled-components'
import theme from 'styles/theme'
import { rem } from 'polished'
import { isMobile } from 'react-device-detect'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Body20 as BaseBody20,
  Title20 as BaseTitle20,
  Body20,
  Title60,
} from 'components/Typography'

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const HeroWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.125rem;
  margin: 0 auto;
  height: ${rem(404)};
  width: 100%;

  ${theme.mediaQueries.mobile} {
    height: ${rem(414)};
  }

  ${(props) => `background-color: #${props.hexValue};`}
`

export const CustomTitle = styled(Title60)`
  font-size: ${rem(50)};
  font-family: SangbleuKindgdom-Light, serif;
  font-style: normal;

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(34)};
  }
`

export const DescriptionWrapper = styled.div`
  width: ${rem(670)} ${theme.mediaQueries.mobile} {
    font-size: ${rem(16)};
  }
`

export const CustomDescription = styled(Body20)`
  font-family: BrownStd-Light, serif;
  font-style: normal;
  padding: 0 10%;
`

export const HeroTitle = styled.div`
  color: ${theme.colors.white};
  text-align: center;
  margin-top: ${rem(100)};

  ${theme.mediaQueries.mobile} {
    margin-top: ${rem(50)};
  }
`

export const HeroText = styled.div`
  font-family: BrownStd-Light, serif;
  font-weight: 300;
  color: ${theme.colors.white};
  text-align: center;
`

export const NavContainer = styled.div`
  z-index: 1;
  background-color: ${theme.colors.white};
  width: 100%;
  transition: opacity 0.5s, max-height 0.5s;
  padding: 0;
  height: 100%;
  overflow: auto;
`

export const NavContentContainer = styled.div`
  transition: padding-top 0.8s, column-gap 0.8s;
  display: flex;
  flex-direction: row;
  z-index: 3;
  width: 100%;
  padding-top: 0;
  column-gap: 0;
  justify-content: flex-start;

  ${(props) => (props.editionsLength <= 5 ? `justify-content: center;` : '')}

  ${theme.mediaQueries.medium} {
    padding-top: ${rem(3)};
    column-gap: ${rem(3)};
  }

  ${theme.mediaQueries.mobile} {
    ${(props) =>
      isMobile && props.editionsLength <= 2 ? `justify-content: center;` : ''}
  }
`

export const NavigationItem = styled.button`
  transition: height 0.8s, width 0.8s, border-bottom-color 0.8s;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
  position: relative;
  min-width: 50vw;
  max-width: 50vw;
  height: calc(50vw + 25vw);
  box-sizing: border-box;
  border: none;
  background-color: transparent;

  &::before {
  }

  ${(props) => `background-image: url(${props.backgroundImage});`}

  ${(props) =>
    `border-bottom: ${
      props.isActive
        ? `4px solid ${theme.colors.gray}`
        : `0px solid ${theme.colors.white}`
    };`}

  ${theme.mediaQueries.medium}{
    max-width: calc(20vw - ${rem(7)});
    min-width: calc(20vw - ${rem(7)});
    height: calc(20vw + 6vw);
  }
`

export const ItemBackgroundImage = styled.div`
  transition: opacity 0.5s, height 0.8s;
  width: 100%;
  height: 100%;
  position: absolute;
`

export const ItemImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
`

export const ItemBackgroundOpactiy = styled.div`
  background-color: ${theme.colors.black};
  opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: 4;
  position: absolute;
`

export const CategoryTitle = styled(BaseTitle20)`
  font-size: ${rem(22)};
  color: ${theme.colors.white};
  text-align: center;
  z-index: 5;
  white-space: normal;
  width: ${rem(200)};
  overflow-wrap: break-word;
  font-style: italic;
  width: 100%;

  ${theme.mediaQueries.large} {
    font-size: ${rem(28)};
  }
`

export const CategoryCopy = styled(BaseBody20)`
  color: ${theme.colors.white};
  width: 100%;
  font-family: SangbleuKindgdom-Light, serif;
  font-weight: 200;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: ${rem(12)};
  z-index: 5;

  ${theme.mediaQueries.large} {
    font-size: ${rem(20)};
  }
`

export default {
  CategoryCopy,
  CategoryTitle,
  HeroWrapper,
  HeroTitle,
  HeroText,
  NavigationItem,
  HeroContainer,
  NavContentContainer,
  ItemBackgroundImage,
  ItemBackgroundOpactiy,
}
