/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { transitions } from 'polished'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`
export const Content = styled.div`
  ${(props) =>
    transitions(
      `max-height ${props?.delay ?? 0.5}s`,
      `opacity ${(props?.delay && props.delay - 0.25) ?? 0.5}s`
    )}
  opacity: 1;
  max-height: 0px;
  overflow: hidden;

  ${(props) =>
    props?.open &&
    `
    opacity: 1;
  `}
`
