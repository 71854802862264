import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

function Portal({ children }) {
  return createPortal(
    <>
      {children}
    </>
    ,
    document.body
  )
}

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

Portal.defaultProps = {
  background: undefined,
}

const FullscreenModal = React.forwardRef(Portal)

export default FullscreenModal
