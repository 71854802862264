import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import theme from '../../styles/theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 40px);
  max-width: ${theme.maxWidths[1]};
  height: ${(props) => (props.isMobileOnly ? '84%' : '100%')};
  position: relative;

  #carousel-item-modal--shop-contacts-large {
    display: none;
  }

  #carousel-item-modal--shop-contacts-small {
    display: flex;
    flex-direction: column;
    padding-top: ${theme.space[7]};
    padding-bottom: ${theme.space[7]};
  }

  #carousel-item-modal--close-large {
    display: none;
  }

  #carousel-item-modal--close-small {
    display: flex;
  }

  .slick-slider {
    width: calc(100vw - (${theme.space[4]} * 4));
  }

  a {
    color: ${theme.colors.sagedark};
    text-decoration: underline;
  }

  ${theme.mediaQueries.medium} {
    padding-bottom: 0;
    height: auto;

    #carousel-item-modal--shop-contacts-small {
      display: none;
    }

    #carousel-item-modal--shop-contacts-large {
      display: flex;
      flex-direction: column;
    }

    #carousel-item-modal--close-large {
      display: flex;
      margin: 0px;
      padding: 0px;
      float: right;
    }

    #carousel-item-modal--close-small {
      display: none;
    }

    .slick-slider {
      padding-top: 50px;
      padding-left: 20px;
      height: 480px;
      width: auto;
      max-width: 540px;
    }
  }
`

export const ContentWrapper = styled.div`
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`

export const SliderWrapper = styled.div`
  .slick-list {
    overflow: ${(props) => (props.overflow ? 'visible' : 'hidden')};
  }
  /* Slider */
  .slick-slider {
    display: block;
    margin: 0px;
    padding: 0px;
    align-self: center;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    /* overflow: hidden; */

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  /* Dots */
  .slick-dots {
    position: relative;
    display: block;
    width: 100%;
    height: 30px;
    padding: 0;
    margin: 0;
    padding-top: 10px;
    list-style: none;
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    padding: 0;
    cursor: none;
    transition: width 0.3s ease-in-out;
  }

  /* Custom Slick Dots */
  .slick-dots li {
    width: 8px;
    height: 8px;
    transition: width 0.3s ease-in-out;
    background-color: transparent;
    border: 1px solid #455a64;
  }

  .slick-dots .slick-active {
    width: 8px;
    height: 8px;
    transition: width 0.3s ease-in-out;
    background-color: #455a64;
  }

  .slick-track {
    display: block;
    left: 0px;
    position: relative;
    top: 0;
  }
`

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  ${theme.mediaQueries.medium} {
    min-width: 480px;
  }
`

export const InnerWrapper = styled.div`
  display: block;
  flex: 1;
  position: relative;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: hsla(0, 0%, 0%, 0.25);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ${theme.mediaQueries.medium} {
    flex-direction: row;
    overflow-y: hidden;
    display: flex;
  }
`

export const ImagePreviewWrapper = styled.div`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: ${theme.space[3]};

  ${theme.mediaQueries.medium} {
    display: flex;
    /* border: 1px solid black; */
    width: 100%;
  }
`

export const ProductImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColour ? `#${props.backgroundColour}` : `white`};
`

export const ProductImg = styled(GatsbyImage)`
  display: flex;
  height: calc((100vw - (${theme.space[4]} * 4)));
  width: calc((100vw - (${theme.space[4]} * 4)) * 0.625);
  margin: 0 auto;

  img {
    object-fit: cover;
    border: 3px solid rgba(255, 255, 255, 0.2);
  }

  ${theme.mediaQueries.medium} {
    height: 480px;
    width: ${(props) =>
      props.image.height > 1 ? 480 / props.image.height : 480}px;
  }
`

export const StyledCarouselControls = styled.div`
  position: relative;
  right: ${theme.space[7]};
  bottom: -${theme.space[7]};
`
