import React, { useEffect, useRef } from 'react'
import { Spacing20 } from 'styles/sharedStyle'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import Link from 'components/Link'
import NewProductModalCarousel from 'components/NewProductModalCarousel/NewProductModalCarousel'
import {
  checkExternalUrl,
  normalizeExternalUrl,
  normalizeInternalUrl,
} from 'utils/urlUtils'

import {
  ButtonWrapper,
  OfferBrand,
  OfferContainer,
  OfferTitle,
  WrapperMobile,
  GridPlacement,
  Closer,
  OfferPrice,
  OfferPrices,
  ImageContainer,
  ImageStyle,
  OfferImagesContainer,
  OfferButton,
  OfferPriceLabel,
} from 'components/NewOfferCardDetail/NewGiftGuideProductCardDetails.styles'
import currencyUtils from 'utils/currency'

const NewOfferCardDetailMobile = ({
  id: productId,
  gridRow,
  productDescription,
  brand,
  image,
  moreImages,
  settings,
  close,
  rrpPrice,
  villagePrice,
  salePrice,
  village,
  nodeLocale,
}) => {
  const backgroundColor = settings.productPopupBg
  const invertedColor = 'fff'

  let ctsSingleButton

  if (settings.cta1Label && settings.cta2Label) {
    ctsSingleButton = false
  } else if (settings.cta1Label || settings.cta2Label) {
    ctsSingleButton = true
  } else {
    ctsSingleButton = false
  }

  const onCTAClick = (to) => {
    if (checkExternalUrl(to)) {
      window.location.href = normalizeExternalUrl(to)
      return
    }
    navigate(`/${village.villageSlug}/${nodeLocale}${normalizeInternalUrl(to)}`)
  }

  const wrapperRef = useRef(null)

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [wrapperRef.current, productId])

  const MoreImagesDisplay = () => {
    return (
      <>
        {moreImages && moreImages.length > 2 && (
          <NewProductModalCarousel
            productImage={image}
            productMoreImages={moreImages}
            villageName={village.name}
            settings={settings}
          />
        )}
        {moreImages && moreImages.length < 3 && (
          <ImageContainer count={moreImages.length}>
            {moreImages.map((imageItem) => (
              <ImageStyle
                key={`gift-guide-prod-card-image-${imageItem.imageId}`}
                image={imageItem?.portrait?.gatsbyImageData}
                alt={imageItem?.altText ?? 'gift-guide-image'}
                layout="constrained"
              />
            ))}
          </ImageContainer>
        )}
      </>
    )
  }

  return (
    isMobile && (
      <GridPlacement gridRow={gridRow}>
        <WrapperMobile ref={wrapperRef}>
          <OfferContainer background={backgroundColor}>
            <Closer
              hexcode={`#${invertedColor}`}
              color={`#${backgroundColor}`}
              onClick={close}
            />
            <OfferBrand>
              <Link
                to={`${village.villageSlug}/${nodeLocale}/${brand?.pageType?.slug}/${brand?.brandSlug}`}>
                {brand?.brandName}
              </Link>
            </OfferBrand>
            <Spacing20 />
            <OfferTitle color={invertedColor}>{productDescription}</OfferTitle>
            <OfferPrices>
              {rrpPrice ? (
                <>
                  <OfferPriceLabel>{settings.rrpPriceLabel}:</OfferPriceLabel>
                  <OfferPrice strikeThrough>
                    {currencyUtils[village.currency](rrpPrice, {
                      simple: true,
                    })}
                  </OfferPrice>
                </>
              ) : null}
              {villagePrice ? (
                <>
                  <OfferPriceLabel>
                    {settings.villagePriceLabel}:
                  </OfferPriceLabel>
                  <OfferPrice strikeThrough={!!salePrice}>
                    {currencyUtils[village.currency](villagePrice, {
                      simple: true,
                    })}
                  </OfferPrice>
                </>
              ) : null}
              {salePrice ? (
                <>
                  <OfferPriceLabel>{settings.salePriceLabel}:</OfferPriceLabel>
                  <OfferPrice>
                    {currencyUtils[village.currency](salePrice, {
                      simple: true,
                    })}
                  </OfferPrice>
                </>
              ) : null}
            </OfferPrices>
            <Spacing20 />
            <OfferImagesContainer>
              {moreImages ? (
                <MoreImagesDisplay />
              ) : (
                <ImageContainer count={1}>
                  <ImageStyle
                    image={image?.portrait?.gatsbyImageData}
                    alt={image?.altText ?? 'gift-guide-image'}
                    layout="constrained"
                  />
                </ImageContainer>
              )}
            </OfferImagesContainer>
            <ButtonWrapper single={ctsSingleButton}>
              {settings.cta1Label && (
                <OfferButton
                  backgroundColour={settings.primaryBg}
                  borderColour={settings.primaryBg}
                  shadow
                  onClick={() => onCTAClick(settings.cta1Url)}>
                  {settings.cta1Label}
                </OfferButton>
              )}
              {settings.cta2Label && (
                <OfferButton
                  borderColour="FFF"
                  onClick={() => onCTAClick(settings.cta2Url)}>
                  {settings.cta2Label}
                </OfferButton>
              )}
            </ButtonWrapper>
            <Spacing20 />
          </OfferContainer>
        </WrapperMobile>
      </GridPlacement>
    )
  )
}

NewOfferCardDetailMobile.propTypes = {
  id: PropTypes.string,
  gridRow: PropTypes.number,
  productDescription: PropTypes.string,
  rrpPrice: PropTypes.string,
  villagePrice: PropTypes.string,
  salePrice: PropTypes.string,
  village: PropTypes.shape({
    name: PropTypes.string,
    villageSlug: PropTypes.string,
    currency: PropTypes.string,
  }),
  brand: PropTypes.shape({
    brandName: PropTypes.string,
    pageType: PropTypes.string,
    brandSlug: PropTypes.string,
  }),
  image: PropTypes.shape({
    altText: PropTypes.string,
    portrait: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    landscape: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    __typename: PropTypes.string,
  }),
  moreImages: PropTypes.arrayOf(
    PropTypes.shape({
      imageId: PropTypes.string,
      altText: PropTypes.string,
      portrait: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
      }),
      landscape: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
      }),
      __typename: PropTypes.string,
    })
  ),
  settings: PropTypes.shape({
    productPopupBg: PropTypes.string,
    primaryBg: PropTypes.string,
    secondaryBg: PropTypes.string,
    cta1Label: PropTypes.string,
    cta1Url: PropTypes.string,
    cta1Mode: PropTypes.string,
    cta2Label: PropTypes.string,
    cta2Url: PropTypes.string,
    cta2Mode: PropTypes.string,
    rrpPriceLabel: PropTypes.string,
    villagePriceLabel: PropTypes.string,
    salePriceLabel: PropTypes.string,
  }),
  close: PropTypes.func,
  nodeLocale: PropTypes.string,
}

NewOfferCardDetailMobile.defaultProps = {
  id: '0',
  gridRow: 0,
  productDescription: '',
  rrpPrice: '',
  villagePrice: '',
  salePrice: '',
  village: {
    name: '',
    villageSlug: '',
    currency: '',
  },
  brand: {
    brandName: '',
    pageType: '',
    brandSlug: '',
  },
  image: {
    altText: '',
    portrait: { gatsbyImageData: {} },
    landscape: { gatsbyImageData: {} },
    __typename: '',
  },
  moreImages: [],
  close: () => {},
  settings: {
    productPopupBg: '',
    primaryBg: '',
    secondaryBg: '',
    cta1Label: '',
    cta1Url: '',
    cta1Mode: '',
    cta2Label: '',
    cta2Url: '',
    cta2Mode: '',
    rrpPriceLabel: '',
    villagePriceLabel: '',
    salePriceLabel: '',
  },
  nodeLocale: 'en',
}

export default NewOfferCardDetailMobile
