/* eslint-disable import/prefer-default-export */
import { createContext } from 'react'

export const CATEGORIES_ID = {
  WOMEN: 'Women',
  MENS: 'Mens',
  GIRLS: 'Girls',
  BOYS: 'Boys',
  ALL: 'All',
}

export const ADULT_CATEGORIES = [
  {
    id: `cat-${CATEGORIES_ID.WOMEN}`,
    genderId: CATEGORIES_ID.WOMEN,
    labelId: 'categoryWomen',
    defaultLabel: CATEGORIES_ID.WOMEN,
  },
  {
    id: `cat-${CATEGORIES_ID.MENS}`,
    genderId: CATEGORIES_ID.MENS,
    labelId: 'categoryMen',
    defaultLabel: CATEGORIES_ID.MENS,
  },
]

export const CHILDREN_CATEGORIES = [
  {
    id: `cat-${CATEGORIES_ID.GIRLS}`,
    genderId: CATEGORIES_ID.GIRLS,
    labelId: 'categoryGirls',
    defaultLabel: CATEGORIES_ID.GIRLS,
  },
  {
    id: `cat-${CATEGORIES_ID.BOYS}`,
    genderId: CATEGORIES_ID.BOYS,
    labelId: 'categoryBoys',
    defaultLabel: CATEGORIES_ID.BOYS,
  },
]

export const ALL_GENDER = {
  id: `cat-${CATEGORIES_ID.ALL}`,
  genderId: CATEGORIES_ID.ALL,
  labelId: 'categoryAll',
  defaultLabel: CATEGORIES_ID.ALL,
}

export const contextInitialState = {
  products: [],
  promoGiftCards: [],
  promoCrossSellingCards: [],
  editions: [],
  editionProductLink: {},
  underPrice: 100,
  abovePrice: 250,
  adultCategories: ADULT_CATEGORIES,
  childrenCategories: CHILDREN_CATEGORIES,
}

export const contextDefinition = createContext(contextInitialState)

export const extractProducts = (data) => {
  if (!data || data?.length < 1)
    return {
      editions: [],
      editionProductLink: {},
      products: [],
      promoCrossSellingCards: [],
    }

  const editions = []
  const uniqueProducts = []
  const editionProductLink = {}

  const allProducts = data.flatMap((dataItem) => {
    editions.push({
      id: dataItem.id,
      contentful_id: dataItem.contentful_id,
      title: dataItem.title,
      titleCopy: dataItem.titleCopy,
      image: dataItem.image,
      altImage: dataItem.altImage,
      richMedia: dataItem.richMedia,
      showTitleInRichMedia: dataItem.showTitleInRichMedia,
      slug: dataItem.slug,
    })

    editionProductLink[dataItem.id] = dataItem.products.map(
      ({ productId }) => productId
    )

    return dataItem.products
  })

  allProducts.forEach((product) => {
    if (
      !uniqueProducts.some(({ productId }) => productId === product.productId)
    ) {
      uniqueProducts.push(product)
    }
  })

  return {
    editions,
    editionProductLink,
    products: uniqueProducts,
    promoCrossSellingCards: [].concat(editions),
  }
}

export const getById = (array, targetId) =>
  array.find(({ id }) => id === targetId)
