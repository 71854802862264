import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Portal from 'components/Portal'

import { BottomPanel } from './style'

const FIBottomPanel = ({ children, open, bg }) => {
  const [display, setDisplay] = useState(false)

  useLayoutEffect(() => {
    if (open) {
      setDisplay(() => open)

      return () => null
    }
    const timoutRef = setTimeout(() => setDisplay(() => open), 375)

    return () => clearTimeout(timoutRef)
  }, [open])

  if (!display) return null

  return (
    <Portal>
      <BottomPanel bg={bg} open={open}>
        {children}
      </BottomPanel>
    </Portal>
  )
}

FIBottomPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, null]),
  open: PropTypes.bool,
  bg: PropTypes.string,
}

FIBottomPanel.defaultProps = {
  children: null,
  open: false,
  bg: null,
}

export default FIBottomPanel
