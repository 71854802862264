import React from 'react'
import PropTypes from 'prop-types'
import useScrollListener, { SCROLL_DIRECTIONS } from 'hooks/useScrollListener'
import FloatingIsland from 'components/FloatingIsland/FloatingIsland'

const FIScrollListener = ({ children }) => {
  const { lastScrollDirection } = useScrollListener({
    delayAmount: { scroll: 250, iddle: 250 },
    scrollEventName: 'scroll',
  })

  return (
    <FloatingIsland scrollingTop={lastScrollDirection === SCROLL_DIRECTIONS.up}>
      {children}
    </FloatingIsland>
  )
}

FIScrollListener.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
    null,
  ]),
}

FIScrollListener.defaultProps = {
  children: null,
}

export default FIScrollListener
