/* eslint-disable import/prefer-default-export,import/no-cycle,no-useless-escape,no-param-reassign */
import { createContext } from 'react'
import { navigate } from 'gatsby'

import { ALL_GENDER } from '../content/constants'

export const FILTER_TYPE = {
  CATEGORY: 'CATEGORY',
  EDITION: 'EDITION',
  PRICE: 'PRICE',
}

export const PRICE_TYPE = {
  UNDER: 'underPrice',
  BETWEEN: 'betweenPrice',
  ABOVE: 'abovePrice',
}

export const SLUG_TYPES = {
  CATEGORIES: 'categories',
  ADULT: 'adult',
  CHILDREN: 'children',
  UNDER: PRICE_TYPE.UNDER,
  BETWEEN: PRICE_TYPE.BETWEEN,
  ABOVE: PRICE_TYPE.ABOVE,
}

// const activeFiltersInitialState = {
//   [FILTER_TYPE.EDITION]: [],
//   [FILTER_TYPE.CATEGORY]: [],
//   [FILTER_TYPE.PRICE]: []
// }

export const contextInitialState = {
  active: [],
  editionProductLink: {},
  location: {
    pathname: '',
    search: '',
    hash: '',
  },
  onSelectFilter: () => null,
  applyFilters: () => null,
  clearAllFilters: () => null,
  clearCategories: () => null,
  clearPrices: () => null,
}

export const contextDefinition = createContext(contextInitialState)

export const dynamicRegex = (pattern, flags = 'gi') =>
  new RegExp(pattern, flags)

export const isFunction = (target) => target && typeof target === 'function'
export const isSubstring = (string, slice) =>
  dynamicRegex(slice, 'gim').test(string)

export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true
  }

  if (typeof value === 'object') {
    return Object.keys(value).length <= 0
  }

  if (typeof value === 'string') {
    return value === ''
  }

  if (typeof value === 'function') {
    return value === undefined || value === null
  }

  if (value?.length) {
    return value.length <= 0
  }

  return true
}

// functions for UI interation, like selecting a filter or deselecting it
export const addFilter = (filters, target) => [...filters, target]

export const removeFilter = (filters, target) => {
  const indexOf = filters.findIndex(({ id }) => id === target.id)
  return filters.toSpliced(indexOf, 1)
}

export const handleFilterChange = (filters, target) => {
  if (filters.some(({ id }) => id === target.id)) {
    return removeFilter(filters, target)
  }

  return addFilter(filters, target)
}

// extract only the needed info to render the edit by set of filters
export const getEditionProductLink = (contentData) => {
  if (!contentData || contentData.length < 1) return {}

  const editionProductLink = {}

  contentData.forEach((contentItem) => {
    editionProductLink[contentItem.id] = contentItem.products.map(
      ({ productId }) => productId
    )
  })

  return editionProductLink
}

// functions related to preloaded filters from the search query params
const mountKeyValue = (key, values, obj) => {
  if (!values[0]) return obj

  /* eslint-disable-next-line */
  if (!obj[key]) obj[key] = []

  if (values.length < 2) {
    obj[key].push(values[0])
    return obj
  }

  obj[key].push(values.shift())
  return mountKeyValue(key, values, obj)
}

const mountQueryObj = (array, obj) => {
  if (!array[0] || !array[0]?.split('=')[1]) return obj

  if (array.length < 2)
    return mountKeyValue(
      array[0].split('=')[0],
      array[0].split('=')[1].split(','),
      obj
    )

  const paramSection = array.shift()
  return mountQueryObj(
    array,
    mountKeyValue(
      paramSection.split('=')[0],
      paramSection.split('=')[1].split(','),
      obj
    )
  )
}

export const parseQueryParams = (data) => {
  const queryData = data.split('?')

  if (queryData.length < 1 || !queryData[1]) return {}

  return mountQueryObj(queryData[1].split('&'), {})
}

// shorhand function to execute a filter on an array
export const execFilter = (array, filterFunc) => array.filter(filterFunc)

export const execSome = (array, func) => array.some((item) => func(item))

export const execFind = (array, func) => array.find((item) => func(item))

// agnostic apply filters no matter its type to the content
export const applyFilters = (array, filters, result = []) => {
  const { filter } = filters.shift()
  if (filters.length < 2) return execFilter(array, filter)

  return applyFilters(array, filters, execFilter(result, filters))
}

// functions which implements the different types of way to filter the content
export const filterByUnderPrice = (
  { salePrice, villagePrice },
  targetPrice
) => {
  if (!salePrice && !villagePrice) return false

  const price = Number(targetPrice) + 5

  if (salePrice) {
    return Number(salePrice) <= price
  }

  return Number(villagePrice) <= price
}

export const filterByBetweenPrices = (
  { salePrice, villagePrice },
  min,
  max
) => {
  if (!salePrice && !villagePrice) return false

  const minPrice = Number(min) - 5
  const maxPrice = Number(max) + 5

  if (salePrice) {
    return Number(salePrice) >= minPrice && Number(salePrice) <= maxPrice
  }

  return Number(villagePrice) >= minPrice && Number(villagePrice) <= maxPrice
}

export const filterByAbovePrice = (
  { salePrice, villagePrice },
  targetPrice
) => {
  if (!salePrice && !villagePrice) return false

  const price = Number(targetPrice) - 5

  if (salePrice) {
    return Number(salePrice) >= price
  }
  return Number(villagePrice) >= price
}

export const filterByEdition = (
  { productId },
  editionId,
  editionProductLink
) => {
  return execSome(
    editionProductLink[editionId],
    (linkedProdId) => linkedProdId === productId
  )
}

const filterByGender = (productGender, filterGenderId) => {
  if (isEmpty(productGender)) return false

  if (productGender === ALL_GENDER.genderId || productGender === filterGenderId)
    return true

  return false
}

export const filterByAdultGender = ({ adultGender }, filterGenderId) =>
  filterByGender(adultGender, filterGenderId)

export const filterByChildrenGender = ({ childrenGender }, filterGenderId) =>
  filterByGender(childrenGender, filterGenderId)

const excludingFilter = (products, filters, index = 0) => {
  if (products.length < 1 || filters.length < 1 || index === filters.length) {
    return products
  }

  return excludingFilter(
    execFilter(products, filters[index].filter),
    filters,
    index + 1
  )
}

const execCombinedFilters = (products, filters) =>
  execFilter(products, (product) =>
    execSome(filters, ({ filter }) => filter(product))
  )

const combiningFilter = (products, filters) => {
  if (isEmpty(filters)) return products

  let combinedResult = [...products]
  const filtersTypes = Object.keys(filters)

  filtersTypes.forEach((filterType) => {
    combinedResult = execCombinedFilters(combinedResult, filters[filterType])
  })

  return combinedResult
}

const handleCombineFilters = (combinedGroupObj, filterType, filter) => {
  if (isEmpty(combinedGroupObj[filterType])) {
    combinedGroupObj[filterType] = [filter]
    return null
  }

  combinedGroupObj[filterType].push(filter)
  return null
}

const specifyFilters = (allFilters, specificFilters = [[], []], index = 0) => {
  if (index === allFilters.length) return specificFilters

  if (allFilters[index].excludingFilter) {
    specificFilters[1].push(allFilters[index])
  } else {
    handleCombineFilters(
      specificFilters[0],
      allFilters[index].type,
      allFilters[index]
    )
  }

  return specifyFilters(allFilters, specificFilters, index + 1)
}

export const filterProducts = (products, filters) => {
  const [combiningFilters, excludingFilters] = specifyFilters(filters)
  const filterCombinedProducts = combiningFilter(products, combiningFilters)
  return excludingFilter(filterCombinedProducts, excludingFilters)
}

export const removeHashSection = (hash, sectionId) => {
  if (isEmpty(hash)) return ''

  const pureHash = dynamicRegex('#').test(hash) ? hash.split('#')[1] : hash
  const cleanHash = pureHash
    .split('&')
    .filter((section) => section.split('=')[0] !== sectionId)
  const result = cleanHash.join('&')

  return result
}

const removeQueryFilter = (hashSection, targetValue, hasOtherSections) => {
  const [filterType, rawValues] = hashSection.split('=')

  if (!filterType || !rawValues) return ''

  const values = rawValues.split(',')

  if (values.length < 2) return ''

  const cleanValues = values.filter((value) => value !== targetValue)

  if (cleanValues.length < 1) return ''

  return `${hasOtherSections ? '&' : ''}${filterType}=${
    cleanValues.length > 1 ? cleanValues.join(',') : cleanValues[0]
  }`
}

export const handleHashSlugChange = (
  currentHash,
  typeResolver,
  filterType,
  filterValue
) => {
  const containsFilterType = isSubstring(
    currentHash,
    dynamicRegex(`${filterType}=`, 'gi')
  )

  const cleanHash = currentHash.split('#')[1] ?? ''
  const querySections = cleanHash.split('&') ?? []
  const prefix = currentHash === '' ? '#' : `${currentHash}&`

  if (currentHash.length < 3 || !containsFilterType) {
    return `${prefix}${filterType}=${filterValue}`
  }

  const targetSectionIndex = querySections.findIndex((hashSection) =>
    isSubstring(hashSection, filterType)
  )

  const targetSection = querySections.splice(targetSectionIndex, 1)[0]

  const containsFilterValue = isSubstring(
    currentHash,
    dynamicRegex(`(\w|-|_|\,)*${filterValue}(\w|-|_|\,)*`)
  )

  const hasMultipleQueries = querySections.length > 0

  const joinScheme = hasMultipleQueries ? `#${querySections.join('&')}` : '#'
  if (containsFilterValue) {
    return `${joinScheme}${removeQueryFilter(
      targetSection,
      filterValue,
      hasMultipleQueries
    )}`
  }

  return `${joinScheme}${hasMultipleQueries ? '&' : ''}${typeResolver(
    targetSection,
    filterType
  )}`
}

export const updateHashUrl = (newHash) => {
  if (window !== undefined) {
    if (newHash === '#') {
      window.location.hash = '?'
      return
    }
    window.location.hash = newHash
  }
}

export const handleSearchQueryChange = (value) => value

export const appendToUrl = (newHash) => {
  navigate(newHash ?? '')
}
