import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { contextDefinition as settingsContext } from 'contexts/giftGuide/settings'

import {
  GiftGuideCardContainer,
  GiftGuideCardWrapper,
  GiftGuideCardImg,
  GiftGuideCardBody,
  GiftGuideCardTitle,
} from './NewGiftGuideProductCard.style'

const NewGiftGuideProductCard = ({
  index,
  brand,
  media,
  showFullGrid,
  columnIndex,
  isModalOpen,
  setIndexSelected,
  setIsCardSelected,
  isCardSelected,
}) => {
  const settings = useContext(settingsContext)

  const handleModalOpen = () => {
    setIsCardSelected(true)
    setIndexSelected(index)
  }

  return (
    <GiftGuideCardContainer
      showFullGrid={showFullGrid}
      columnIndex={columnIndex}
      id={`gift-guide${index}`}
      selected={isModalOpen}
      isCardSelected={isCardSelected}>
      <GiftGuideCardWrapper
        data-element-identifier="isGiftGuideProduct"
        onClick={handleModalOpen}>
        <GiftGuideCardImg
          selected={isModalOpen}
          cardColor={settings.productCardOverlayColor}
          image={media?.portrait?.gatsbyImageData}
          alt={media?.altText ?? 'offer-image'}
        />
        <GiftGuideCardBody>
          <GiftGuideCardTitle color={settings.productCardTextColor}>
            {brand?.brandName || ''}
          </GiftGuideCardTitle>
        </GiftGuideCardBody>
      </GiftGuideCardWrapper>
    </GiftGuideCardContainer>
  )
}

NewGiftGuideProductCard.propTypes = {
  index: PropTypes.number,
  media: PropTypes.shape({
    altText: PropTypes.string,
    portrait: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    landscape: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    __typename: PropTypes.string,
  }),
  brand: PropTypes.shape({ brandName: PropTypes.string }),
  showFullGrid: PropTypes.bool,
  columnIndex: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool,
  setIndexSelected: PropTypes.func,
  setIsCardSelected: PropTypes.func,
  isCardSelected: PropTypes.bool,
}

NewGiftGuideProductCard.defaultProps = {
  index: 0,
  brand: {
    brandName: '',
  },
  media: {
    altText: '',
    portrait: { gatsbyImageData: {} },
    landscape: { gatsbyImageData: {} },
    __typename: '',
  },
  isModalOpen: false,
  showFullGrid: false,
  setIndexSelected: () => {},
  setIsCardSelected: () => {},
  isCardSelected: false,
}

export default NewGiftGuideProductCard
