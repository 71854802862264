import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { contextDefinition as settingsContext } from 'contexts/giftGuide/settings'

import { AnimatedContainer } from './style'

const FIAnimatedDisplayContainer = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const { islandState } = useContext(settingsContext)
  const { enabled, visible: islandVisible } = islandState

  useEffect(() => {
    if (enabled && islandVisible) {
      setVisible(true)
      return () => null
    }

    if (!enabled || !islandVisible) {
      const timeout = setTimeout(() => setVisible(false), 250)
      return () => clearTimeout(timeout)
    }

    return () => null
  }, [enabled, islandVisible])

  if (!visible) return null

  return (
    <AnimatedContainer hide={!enabled || !islandVisible}>
      {children}
    </AnimatedContainer>
  )
}

FIAnimatedDisplayContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
    null,
  ]),
}

FIAnimatedDisplayContainer.defaultProps = {
  children: null,
}

export default FIAnimatedDisplayContainer
