/* eslint-disable import/prefer-default-export */
import React, { useEffect, useMemo, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  contextDefinition as settingsContext
} from 'contexts/giftGuide/settings'

import {
  SLUG_TYPES,
  applyFilters,
  contextDefinition,
  contextInitialState,
  getEditionProductLink,
  execFilter,
  execSome,
  handleFilterChange,
  isFunction,
  updateHashUrl,
  removeHashSection,
} from './constants'

import fillPreloadedFilters from './preLoadFilters'

const ProductGuideFilterContext = ({ children, location, content }) => {
  const settings = useContext(settingsContext)
  const [activeFilters, setActiveFilters] = useState(contextInitialState.active)
  const editionProductLink = useMemo(() => getEditionProductLink(content), [content])

  const onSelectFilter = ({ filterObj, urlResolver, beforeAdd }) => {
    setActiveFilters(state => {
      if(isFunction(beforeAdd)) {
        return handleFilterChange(beforeAdd(state), filterObj)
      }

      return handleFilterChange(state, filterObj)
    })

    urlResolver()
  }

  const clearAllFilters = () => {
    if(!window || window?.location === undefined) return null

    window.location.hash = '?'
  }

  const clearCategories = (categoryIds, hashSectionId) => {
    setActiveFilters(
      state => execFilter(
        state,
        ({ id }) => !execSome(categoryIds, (category) => category.id === id)
      )
    )
    updateHashUrl(
      removeHashSection (
        location.hash,
        hashSectionId
      )
    )
  }
  
  const clearPrices = () => {
    setActiveFilters(
      state => execFilter(state, ({ id }) => !/price/gi.test(id))
    )
    updateHashUrl(
      removeHashSection(
        removeHashSection(
          removeHashSection(
            location.hash,
            SLUG_TYPES.ABOVE
          ),
          SLUG_TYPES.BETWEEN
        ),
        SLUG_TYPES.UNDER
      )
    )
  }

  useEffect(() => {
    if(location?.hash && location.hash !== '#') {
      setActiveFilters(
        () => fillPreloadedFilters({
          settings,
          content,
          editionProductLink,
          urlHash: location.hash,
        })
      )
    } else {
      setActiveFilters(() => contextInitialState.active) 
    }
  }, [location.hash])

  return (
    <contextDefinition.Provider
      value={{
        ...contextInitialState,
        active: activeFilters,
        editionProductLink,
        applyFilters,
        onSelectFilter,
        clearAllFilters,
        clearCategories,
        clearPrices,
        location,
      }}
    >
      {children}
    </contextDefinition.Provider>
  )
}

ProductGuideFilterContext.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object, null]),
  content: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  location: PropTypes.shape({
    hash: PropTypes.string,
    search: PropTypes.string,
    pathname: PropTypes.string,
  })
}

ProductGuideFilterContext.defaultProps = {
  children: null,
  content: [],
  location: {
    hash: '',
    search: '',
    pathname: '',
  }
}

export * from './constants'

export default ProductGuideFilterContext