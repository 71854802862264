import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import { isMobileOnly } from 'react-device-detect'
import {
  Wrapper,
  ContentWrapper,
  InnerWrapper,
  CarouselWrapper,
  ProductImg,
  ProductImgContainer,
  SliderWrapper,
  StyledCarouselControls,
  ImagePreviewWrapper,
} from './NewProductModalCarousel.styles'

import CarouselControls from '../Carousel/CarouselControls'

const NewProductModalCarousel = ({
  productImage,
  productMoreImages,
  villageName,
  settings,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(true)

  const sliderRef = React.useRef(null)

  const allProductImages = [productImage]
  if (!!productMoreImages && productMoreImages.length > 0) {
    productMoreImages.map((image) => {
      // Check to ensure image entry is not null or undefined
      if (image != null) {
        allProductImages.push(image)
      }
      return allProductImages
    })
  }

  const onCarouselSwipeChange = useCallback(
    (i) => {
      if (i !== currentSlide) {
        setCurrentSlide(i)
      }
    },
    [currentSlide]
  )

  const onCarouselControlClick = useCallback(
    (i) => {
      setCurrentSlide(i)

      if (sliderRef.current) {
        sliderRef.current.slickGoTo(i)
      }
    },
    [sliderRef]
  )

  // Get scroll element Ref to check if is scrollable
  const elRef = useCallback((node) => {
    if (node !== null) {
      const { scrollHeight, clientHeight } = node

      if (scrollHeight > clientHeight) {
        setTimeout(() => {
          setIsScrollAtBottom(false)
        }, 500)
      }
    }
  }, [])

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight
    if (bottom && !isScrollAtBottom) {
      setIsScrollAtBottom(true)
    } else if (!bottom && isScrollAtBottom) {
      setIsScrollAtBottom(false)
    }
  }

  const carouselSettings = {
    dots: true,
    autoplay: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 500,
    arrows: false,
    adaptiveHeight: true,
    afterChange: onCarouselSwipeChange,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: () => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  }

  return (
    <Wrapper isMobileOnly={isMobileOnly}>
      <ContentWrapper>
        <InnerWrapper
          ref={elRef}
          id="carousel-item-modal--innerWrapper"
          onScroll={handleScroll}>
          <CarouselWrapper id="carousel-item-modal--wrapper">
            <SliderWrapper>
              <Slider {...carouselSettings} ref={sliderRef}>
                {allProductImages.map((item) => (
                  <ProductImgContainer
                    backgroundColour={settings.productPopupBg}>
                    <ProductImg
                      backgroundColor={settings.productPopupBg}
                      image={
                        item?.portrait?.gatsbyImageData ||
                        item?.landscape?.gatsbyImageData
                      }
                      alt="product"
                    />
                  </ProductImgContainer>
                ))}
              </Slider>
              {allProductImages && allProductImages.length > 1 && (
                <StyledCarouselControls>
                  <CarouselControls
                    onSlideChange={onCarouselControlClick}
                    currentSlide={currentSlide}
                    totalSlides={allProductImages.length}
                    hasMultipleItemsPerView={false}
                    isBoxed={false}
                    isSmall={false}
                    villageName={villageName}
                  />
                </StyledCarouselControls>
              )}
            </SliderWrapper>
            <ImagePreviewWrapper id="carousel-item-modal--product-thumbnails" />
          </CarouselWrapper>
        </InnerWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

NewProductModalCarousel.propTypes = {
  productImage: PropTypes.shape({}),
  productMoreImages: PropTypes.arrayOf(PropTypes.shape({})),
  villageName: PropTypes.string,
  settings: PropTypes.shape({
    productPopupBg: PropTypes.string,
  }),
}

NewProductModalCarousel.defaultProps = {
  productImage: '',
  productMoreImages: '',
  villageName: '',
  settings: '',
}

export default NewProductModalCarousel
