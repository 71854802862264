/* eslint-disable no-shadow */
import React, { useMemo, useContext } from 'react'
import { rem } from 'polished'
import FilterIcon from 'components/Icons/FilterIcon'
import {
  IslandRow,
  IslandRowGroup,
} from 'components/FloatingIsland/FloatingIsland.style'
import {
  contextDefinition as contentContext,
  ADULT_CATEGORIES,
  CHILDREN_CATEGORIES,
} from 'contexts/giftGuide/content'
import {
  contextDefinition as filterContext,
  FILTER_TYPE,
  PRICE_TYPE,
  SLUG_TYPES,
  filterByAdultGender,
  filterByChildrenGender,
  filterByEdition,
  filterByUnderPrice,
  filterByBetweenPrices,
  filterByAbovePrice,
  updateHashUrl,
  handleHashSlugChange,
  execFilter,
  execSome,
  isSubstring,
  isEmpty,
  dynamicRegex,
} from 'contexts/giftGuide/filters'
import { contextDefinition as settingsContext } from 'contexts/giftGuide/settings'
import { CloseIcon } from 'components/Icons'
import theme from 'styles/theme'
import {
  getActiveFiltersLabel,
  getCurrencySymbol,
  onActivePricesClick,
} from '../utils'
import FIAnimatedDisplayContainer from '../FIDisplayContainer'
import FIScrollListener from '../FIScrollListener'
import {
  CategoryBtnText,
  CheckIcon,
  CloseIconButton,
  OutlinedButton,
  PhantonButton,
} from '../style'
import {
  Append,
  AppendButton,
  AppendLabel,
  IconButton,
  Grid,
  FloatingContainer,
} from './style'

const PGFIDesktop = () => {
  const settings = useContext(settingsContext)
  const filters = useContext(filterContext)

  const {
    adultCategories,
    abovePrice,
    childrenCategories,
    editions,
    underPrice,
  } = useContext(contentContext)

  const buttonStyleProps = useMemo(
    () => ({
      bg: settings?.primaryBg && `#${settings.primaryBg}`,
      activeBg: settings?.primaryBg && `#${settings.primaryBg}`,
      hoverBg: settings?.primaryBg && `#${settings.primaryBg}`,
      fontSize: rem(16),
    }),
    []
  )

  const activePrices = useMemo(() => {
    const activePrices = {
      under: false,
      between: false,
      above: false,
    }

    filters.active.forEach(({ id }) => {
      if (id === 'underPrice') activePrices.under = true
      if (id === 'betweenPrice') activePrices.between = true
      if (id === 'abovePrice') activePrices.above = true
    })

    return activePrices
  }, [filters])

  const { underPriceLabel, betweenPriceLabel, abovePriceLabel } = useMemo(
    () => ({
      underPriceLabel: `${settings.underPriceLabel} ${getCurrencySymbol(
        settings.village.currency,
        underPrice
      )}`,
      betweenPriceLabel: `${settings.betweenPriceLabel} ${getCurrencySymbol(
        settings.village.currency,
        underPrice
      )}-${getCurrencySymbol(settings.village.currency, abovePrice)}`,
      abovePriceLabel: `${settings.abovePriceLabel} ${getCurrencySymbol(
        settings.village.currency,
        abovePrice
      )}`,
    }),
    [abovePrice, underPrice, settings.village]
  )

  return (
    <FIScrollListener>
      <FIAnimatedDisplayContainer>
        <FloatingContainer bg={`#${settings?.floatingIslandBg}`}>
          <IslandRow
            noWrap
            extraStyle={`
          align-items: stretch;
        `}>
            <IslandRow
              noWrap
              extraStyle={`
            align-items: stretch;
            column-gap: ${rem(16)};
          `}>
              <OutlinedButton onClick={filters.clearAllFilters}>
                {settings?.viewAllLabel}
              </OutlinedButton>
              {filters.active.length > 0 && (
                <PhantonButton
                  onClick={onActivePricesClick(
                    filters.active,
                    settings.setDisplayCategories,
                    settings.setDisplayPrices
                  )}
                  extraStyle="text-decoration: underline;">
                  {getActiveFiltersLabel(filters.active, settings)}
                </PhantonButton>
              )}
            </IslandRow>
            <IslandRowGroup>
              <IconButton
                {...buttonStyleProps}
                onClick={settings.toggleDisplayCategories}
                active={settings.displayCategories}>
                <FilterIcon width={rem(16)} />
              </IconButton>
              <IconButton
                {...buttonStyleProps}
                fontSize={rem(21)}
                onClick={settings.toggleDisplayPrices}
                style={{ alignItems: 'baseline' }}
                active={settings.displayPrices}>
                {getCurrencySymbol(settings?.village?.currency)}
              </IconButton>
            </IslandRowGroup>
          </IslandRow>
        </FloatingContainer>
        {settings.displayCategories && (
          <Append bg={`#${settings?.floatingIslandBg}`}>
            <IslandRow
              noWrap
              extraStyle={`
            align-items: center;
            padding-bottom: ${rem(18)};
          `}>
              <AppendLabel>{settings?.refineEditLabel}</AppendLabel>
              <AppendLabel>
                <CloseIconButton
                  {...buttonStyleProps}
                  onClick={settings.toggleDisplayCategories}>
                  <CloseIcon color={theme.colors.white} width={rem(24)} />
                </CloseIconButton>
              </AppendLabel>
            </IslandRow>
            <Grid>
              {editions.map((item) => {
                const isActive = filters.active.some(({ id }) => id === item.id)
                const itemSlug = isEmpty(item.slug)
                  ? item.contentful_id
                  : item.slug
                return (
                  <AppendButton
                    key={item.id}
                    title={item.title}
                    active={isActive}
                    onClick={() =>
                      filters.onSelectFilter({
                        filterObj: {
                          id: item.id,
                          type: FILTER_TYPE.EDITION,
                          title: item.title,
                          filter: (product) =>
                            filterByEdition(
                              product,
                              item.id,
                              filters.editionProductLink
                            ),
                        },
                        urlResolver: () => {
                          updateHashUrl(
                            handleHashSlugChange(
                              filters.location.hash,
                              (hashSection) => `${hashSection},${itemSlug}`,
                              SLUG_TYPES.CATEGORIES,
                              itemSlug
                            )
                          )
                        },
                      })
                    }>
                    <CategoryBtnText>{item.title}</CategoryBtnText>
                    {isActive && <CheckIcon width={rem(14)} />}
                  </AppendButton>
                )
              })}
            </Grid>

            <IslandRow>
              <AppendLabel pt={24}>{settings?.refineCategoryLabel}</AppendLabel>
            </IslandRow>
            <IslandRow
              noWrap
              extraStyle={`
              justify-content: flex-start;
              column-gap: ${rem(17)};
            `}>
              {adultCategories.map(
                ({ id, genderId, labelId, defaultLabel }) => {
                  const isActive = filters.active.some(
                    (filter) => filter.id === id
                  )
                  const categoryLabel = settings[labelId] ?? defaultLabel
                  return (
                    <AppendButton
                      small
                      key={id}
                      title={categoryLabel}
                      active={isActive}
                      onClick={() => {
                        filters.onSelectFilter({
                          filterObj: {
                            id,
                            type: FILTER_TYPE.CATEGORY,
                            title: categoryLabel,
                            filter: (product) =>
                              filterByAdultGender(product, genderId),
                          },
                          beforeAdd: (currentFilters) => {
                            return execFilter(currentFilters, (filterObj) =>
                              execSome(ADULT_CATEGORIES, (cat) =>
                                isSubstring(filterObj.id, cat.genderId)
                              )
                            )
                          },
                          urlResolver: () => {
                            updateHashUrl(
                              handleHashSlugChange(
                                filters.location.hash,
                                (hashSection) =>
                                  hashSection.replace(
                                    dynamicRegex('=(.+)$', 'gi'),
                                    `=${genderId}`
                                  ),
                                SLUG_TYPES.ADULT,
                                genderId
                              )
                            )
                          },
                        })
                      }}>
                      <CategoryBtnText>{categoryLabel}</CategoryBtnText>
                      {isActive && <CheckIcon width={rem(14)} />}
                    </AppendButton>
                  )
                }
              )}
              <AppendButton
                small
                title={settings?.viewAllLabel ?? 'View all'}
                onClick={() =>
                  filters.clearCategories(ADULT_CATEGORIES, SLUG_TYPES.ADULT)
                }>
                <CategoryBtnText>
                  {settings?.viewAllLabel ?? 'View all'}
                </CategoryBtnText>
              </AppendButton>
            </IslandRow>

            <IslandRow
              noWrap
              extraStyle={`
              justify-content: flex-start;
              column-gap: ${rem(17)};
            `}>
              {childrenCategories.map(
                ({ id, genderId, labelId, defaultLabel }) => {
                  const isActive = filters.active.some(
                    (filter) => filter.id === id
                  )
                  const categoryLabel = settings[labelId] ?? defaultLabel
                  return (
                    <AppendButton
                      small
                      key={id}
                      title={categoryLabel}
                      active={isActive}
                      onClick={() => {
                        filters.onSelectFilter({
                          filterObj: {
                            id,
                            type: FILTER_TYPE.CATEGORY,
                            title: categoryLabel,
                            filter: (product) =>
                              filterByChildrenGender(product, genderId),
                          },
                          beforeAdd: (currentFilters) => {
                            return execFilter(currentFilters, (filterObj) =>
                              execSome(CHILDREN_CATEGORIES, (cat) =>
                                isSubstring(filterObj.id, cat.genderId)
                              )
                            )
                          },
                          urlResolver: () => {
                            updateHashUrl(
                              handleHashSlugChange(
                                filters.location.hash,
                                (hashSection) =>
                                  hashSection.replace(
                                    dynamicRegex('=(.+)$', 'gi'),
                                    `=${genderId}`
                                  ),
                                SLUG_TYPES.CHILDREN,
                                genderId
                              )
                            )
                          },
                        })
                      }}>
                      <CategoryBtnText>{categoryLabel}</CategoryBtnText>
                      {isActive && <CheckIcon width={rem(14)} />}
                    </AppendButton>
                  )
                }
              )}
              <AppendButton
                small
                title={settings?.viewAllLabel ?? 'View all'}
                onClick={() =>
                  filters.clearCategories(
                    CHILDREN_CATEGORIES,
                    SLUG_TYPES.CHILDREN
                  )
                }>
                <CategoryBtnText>
                  {settings?.viewAllLabel ?? 'View all'}
                </CategoryBtnText>
              </AppendButton>
            </IslandRow>
          </Append>
        )}
        {settings.displayPrices && (
          <Append bg={`#${settings?.floatingIslandBg}`}>
            <IslandRow>
              <AppendLabel>{settings?.refinePriceLabel}</AppendLabel>
              <AppendLabel>
                <CloseIconButton
                  {...buttonStyleProps}
                  onClick={settings.toggleDisplayPrices}>
                  <CloseIcon color={theme.colors.white} width={rem(24)} />
                </CloseIconButton>
              </AppendLabel>
            </IslandRow>
            <IslandRow
              noWrap
              extraStyle={`
              justify-content: flex-start;
              column-gap: ${rem(17)};
          `}>
              <AppendButton
                small
                title={underPriceLabel}
                active={activePrices.under}
                onClick={() => {
                  filters.onSelectFilter({
                    filterObj: {
                      id: PRICE_TYPE.UNDER,
                      type: FILTER_TYPE.PRICE,
                      title: underPriceLabel,
                      filter: (product) =>
                        filterByUnderPrice(product, underPrice),
                    },
                    urlResolver: () => {
                      updateHashUrl(
                        handleHashSlugChange(
                          filters.location.hash,
                          (hashSection) => hashSection,
                          PRICE_TYPE.UNDER,
                          underPrice
                        )
                      )
                    },
                  })
                }}>
                <CategoryBtnText>{underPriceLabel}</CategoryBtnText>
                {activePrices.under && <CheckIcon width={rem(14)} />}
              </AppendButton>
              <AppendButton
                small
                title={betweenPriceLabel}
                active={activePrices.between}
                onClick={() => {
                  filters.onSelectFilter({
                    filterObj: {
                      id: PRICE_TYPE.BETWEEN,
                      type: FILTER_TYPE.PRICE,
                      title: betweenPriceLabel,
                      filter: (product) =>
                        filterByBetweenPrices(product, underPrice, abovePrice),
                    },
                    urlResolver: () => {
                      updateHashUrl(
                        handleHashSlugChange(
                          filters.location.hash,
                          (hashSection) => hashSection,
                          PRICE_TYPE.BETWEEN,
                          `${underPrice}-${abovePrice}`
                        )
                      )
                    },
                  })
                }}>
                <CategoryBtnText>{betweenPriceLabel}</CategoryBtnText>
                {activePrices.between && <CheckIcon width={rem(14)} />}
              </AppendButton>
              <AppendButton
                small
                title={abovePriceLabel}
                active={activePrices.above}
                onClick={() => {
                  filters.onSelectFilter({
                    filterObj: {
                      id: PRICE_TYPE.ABOVE,
                      type: FILTER_TYPE.PRICE,
                      title: abovePriceLabel,
                      filter: (product) =>
                        filterByAbovePrice(product, abovePrice),
                    },
                    urlResolver: () => {
                      updateHashUrl(
                        handleHashSlugChange(
                          filters.location.hash,
                          (hashSection) => hashSection,
                          PRICE_TYPE.ABOVE,
                          abovePrice
                        )
                      )
                    },
                  })
                }}>
                <CategoryBtnText>{abovePriceLabel}</CategoryBtnText>
                {activePrices.above && <CheckIcon width={rem(14)} />}
              </AppendButton>
              <AppendButton
                small
                title={settings?.viewAllLabel}
                onClick={filters.clearPrices}>
                <CategoryBtnText>{settings?.viewAllLabel}</CategoryBtnText>
              </AppendButton>
            </IslandRow>
          </Append>
        )}
      </FIAnimatedDisplayContainer>
    </FIScrollListener>
  )
}

export default PGFIDesktop
