import React, { useEffect, useRef } from 'react'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { Spacing16, Spacing40, Spacing20 } from 'styles/sharedStyle'
import useOutsideAlerter from 'hooks/useOutsideAlerter'
import currencyUtils from 'utils/currency'
import Link from 'components/Link'
import NewProductModalCarousel from 'components/NewProductModalCarousel/NewProductModalCarousel'
import {
  checkExternalUrl,
  normalizeExternalUrl,
  normalizeInternalUrl,
} from 'utils/urlUtils'

import {
  Wrapper,
  OfferBorderContainer,
  OfferContainer,
  Closer,
  OfferBrand,
  OfferTitle,
  OfferPrices,
  OfferPrice,
  OfferImagesContainer,
  ButtonWrapper,
  OfferButton,
  ImageContainer,
  ImageStyle,
  OfferPriceLabel,
} from './NewGiftGuideProductCardDetails.styles'

const NewGiftGuideProductCardDetails = ({
  id: productId,
  productDescription,
  brand,
  image,
  moreImages,
  isModalOpen,
  settings,
  close,
  rrpPrice,
  villagePrice,
  salePrice,
  village,
  nodeLocale,
}) => {
  const wrapperRef = useRef(null)
  const backgroundColor = settings.productPopupBg
  const invertedColor = 'fff'

  let ctsSingleButton

  if (settings.cta1Label && settings.cta2Label) {
    ctsSingleButton = false
  } else if (settings.cta1Label || settings.cta2Label) {
    ctsSingleButton = true
  } else {
    ctsSingleButton = false
  }

  const onCTAClick = (to) => {
    if (checkExternalUrl(to)) {
      window.location.href = normalizeExternalUrl(to)
      return
    }
    navigate(`/${village.villageSlug}/${nodeLocale}${normalizeInternalUrl(to)}`)
  }

  useOutsideAlerter(wrapperRef, () => {
    close()
  })

  useEffect(() => {
    if (isModalOpen && wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isModalOpen, wrapperRef.current, productId])

  const MoreImagesDisplay = () => {
    return (
      <>
        {moreImages && moreImages.length > 2 && (
          <NewProductModalCarousel
            productImage={image}
            productMoreImages={moreImages}
            villageName={village.name}
            settings={settings}
          />
        )}
        {moreImages && moreImages.length < 3 && (
          <ImageContainer count={moreImages.length}>
            {moreImages.map((imageItem) => (
              <ImageStyle
                key={`gift-guide-prod-card-image-${imageItem.imageId}`}
                image={imageItem?.portrait?.gatsbyImageData}
                alt={imageItem?.altText ?? 'gift-guide-image'}
                layout="constrained"
              />
            ))}
          </ImageContainer>
        )}
      </>
    )
  }

  return isModalOpen ? (
    <Wrapper showing={isModalOpen}>
      <OfferBorderContainer ref={wrapperRef}>
        <OfferContainer
          background={backgroundColor}
          invertedColor={invertedColor}>
          <Closer
            hexcode={`#${invertedColor}`}
            color={`#${backgroundColor}`}
            onClick={() => {
              close()
            }}
          />
          <Spacing16 />
          <OfferBrand>
            <Link
              to={`${village.villageSlug}/${nodeLocale}/${brand?.pageType?.slug}/${brand?.brandSlug}`}>
              {brand?.brandName}
            </Link>
          </OfferBrand>
          <Spacing40 />
          <OfferTitle color={invertedColor}>{productDescription}</OfferTitle>
          <Spacing20 />
          <OfferPrices>
            {rrpPrice ? (
              <>
                <OfferPriceLabel>{settings.rrpPriceLabel}:</OfferPriceLabel>
                <OfferPrice strikeThrough>
                  {currencyUtils[village.currency](rrpPrice, { simple: true })}
                </OfferPrice>
              </>
            ) : null}
            {villagePrice ? (
              <>
                <OfferPriceLabel>{settings.villagePriceLabel}:</OfferPriceLabel>
                <OfferPrice strikeThrough={!!salePrice}>
                  {currencyUtils[village.currency](villagePrice, {
                    simple: true,
                  })}
                </OfferPrice>
              </>
            ) : null}
            {salePrice ? (
              <>
                <OfferPriceLabel>{settings.salePriceLabel}:</OfferPriceLabel>
                <OfferPrice>
                  {currencyUtils[village.currency](salePrice, {
                    simple: true,
                  })}
                </OfferPrice>
              </>
            ) : null}
          </OfferPrices>
          <Spacing40 />
          <OfferImagesContainer>
            {moreImages ? (
              <MoreImagesDisplay />
            ) : (
              <ImageContainer count={1}>
                <ImageStyle
                  image={image?.portrait?.gatsbyImageData}
                  alt={image?.altText ?? 'gift-guide-image'}
                  layout="constrained"
                />
              </ImageContainer>
            )}
          </OfferImagesContainer>
          <Spacing20 />
          <ButtonWrapper single={ctsSingleButton}>
            {settings.cta1Label && (
              <OfferButton
                backgroundColour={settings.primaryBg}
                borderColour={settings.primaryBg}
                shadow
                onClick={() => onCTAClick(settings.cta1Url)}>
                {settings.cta1Label}
              </OfferButton>
            )}
            {settings.cta2Label && (
              <OfferButton
                borderColour="FFF"
                onClick={() => onCTAClick(settings.cta2Url)}>
                {settings.cta2Label}
              </OfferButton>
            )}
          </ButtonWrapper>
        </OfferContainer>
      </OfferBorderContainer>
    </Wrapper>
  ) : null
}

NewGiftGuideProductCardDetails.propTypes = {
  id: PropTypes.string,
  productDescription: PropTypes.string,
  rrpPrice: PropTypes.string,
  villagePrice: PropTypes.string,
  salePrice: PropTypes.string,
  village: PropTypes.shape({
    name: PropTypes.string,
    villageSlug: PropTypes.string,
    currency: PropTypes.string,
  }),
  brand: PropTypes.shape({
    brandName: PropTypes.string,
    pageType: PropTypes.string,
    brandSlug: PropTypes.string,
  }),
  image: PropTypes.shape({
    altText: PropTypes.string,
    portrait: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    landscape: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    __typename: PropTypes.string,
  }),
  moreImages: PropTypes.arrayOf(
    PropTypes.shape({
      imageId: PropTypes.string,
      altText: PropTypes.string,
      portrait: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
      }),
      landscape: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({}),
      }),
      __typename: PropTypes.string,
    })
  ),
  isModalOpen: PropTypes.bool,
  settings: PropTypes.shape({
    productPopupBg: PropTypes.string,
    primaryBg: PropTypes.string,
    secondaryBg: PropTypes.string,
    cta1Label: PropTypes.string,
    cta1Url: PropTypes.string,
    cta1Mode: PropTypes.string,
    cta2Label: PropTypes.string,
    cta2Url: PropTypes.string,
    cta2Mode: PropTypes.string,
    rrpPriceLabel: PropTypes.string,
    villagePriceLabel: PropTypes.string,
    salePriceLabel: PropTypes.string,
  }),
  close: PropTypes.func,
  nodeLocale: PropTypes.string,
}

NewGiftGuideProductCardDetails.defaultProps = {
  id: '0',
  productDescription: '',
  rrpPrice: '',
  villagePrice: '',
  salePrice: '',
  village: {
    name: '',
    villageSlug: '',
    currency: '',
  },
  brand: {
    brandName: '',
    pageType: '',
    brandSlug: '',
  },
  image: {
    altText: '',
    portrait: { gatsbyImageData: {} },
    landscape: { gatsbyImageData: {} },
    __typename: '',
  },
  moreImages: [],
  isModalOpen: false,
  close: () => {},
  settings: {
    productPopupBg: '',
    secondaryBg: '',
    primaryBg: '',
    cta1Label: '',
    cta1Url: '',
    cta1Mode: '',
    cta2Label: '',
    cta2Url: '',
    cta2Mode: '',
    rrpPriceLabel: '',
    villagePriceLabel: '',
    salePriceLabel: '',
  },
  nodeLocale: 'en',
}

export default NewGiftGuideProductCardDetails
