import { useState, useEffect } from 'react'

function findMultiples(number, limit) {
  const multiplos = []
  for (let i = 0; i <= limit; i += 1) {
    if (i % number === 0) {
      multiplos.push(i)
    }
  }
  return multiplos
}

function useOfferCardDetail(offers, isMobile) {
  const [isCardSelected, setIsCardSelected] = useState(false)
  const [indexSelected, setIndexSelected] = useState(null)
  const gridVisit = findMultiples(5, offers.length)
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [gridRow, setGridRow] = useState(null)

  const close = () => {
    setIsCardSelected(false)
    setIndexSelected(null)
  }

  /**
   * When card selected, set the index of the selected card and set the grid row
   * placement of the detailed offer card this is used for mobile view
   * eg.: card selected in row 2, the detailed card will be placed in row 3
   * this also takes into consideration the adiditon of the visit card
   */
  useEffect(() => {
    if (indexSelected === null) return
    let i = 1
    gridVisit.forEach((item, index) => {
      if (indexSelected > item || (indexSelected === 0 && index === 0)) {
        i += 0.5
      }
    })

    if (i % 2 === 0 && indexSelected % 2 === 0) {
      i += Math.ceil(indexSelected / 2)
    } else if (indexSelected % 2 !== 0) {
      i += Math.ceil(indexSelected / 2) - 1
    } else if (indexSelected !== 0) {
      i += Math.ceil(indexSelected / 2)
    }
    setGridRow(Math.ceil(i))
  }, [indexSelected, gridVisit])

  /**
   * When card selected, set the selected offer
   */
  useEffect(() => {
    if (indexSelected === null) {
      setSelectedOffer(null)
    } else if (offers[indexSelected]) {
      setSelectedOffer(offers[indexSelected])
    }
  }, [isCardSelected, indexSelected])

  /**
   * When card selected and is mobile to show the detailed offer card in mobile view
   */
  const showBigCard =
    indexSelected !== null && selectedOffer && isCardSelected && isMobile

  return {
    isCardSelected,
    indexSelected,
    gridRow,
    selectedOffer,
    showBigCard,
    close,
    setIndexSelected,
    setIsCardSelected,
  }
}

export default useOfferCardDetail
