import React from 'react'
import PropTypes from 'prop-types'
import ArrowIcon from 'components/Icons/ArrowIcon'
import trackGTM from 'utils/trackGTM'
import GA4 from 'utils/GA4'
import { CarouselButtonWrapper, CarouselButton } from './CarouselControls.style'

const CarouselControls = (props) => {
  const {
    currentSlide,
    totalSlides,
    onSlideChange,
    hasMultipleItemsPerView,
    isBoxed,
    isSmall,
    isMobileOnly,
    villageName,
    carouselType,
    introductionTitle,
  } = props
  const step = hasMultipleItemsPerView ? 3 : 1
  const onClick = (indexToSet, buttonPosition) => {
    onSlideChange(indexToSet, buttonPosition)
  }

  const scrollStep = hasMultipleItemsPerView ? 2 : 1

  let nextSlide = currentSlide + scrollStep
  if (nextSlide >= totalSlides) nextSlide = 0

  let prevSlide = currentSlide - step
  if (prevSlide < 0) prevSlide = totalSlides - step

  if (step > 1) {
    const MAX_SLIDE =
      Math.floor(totalSlides / step) * step - (step - (totalSlides % step))

    nextSlide = currentSlide + scrollStep

    if (nextSlide > MAX_SLIDE && nextSlide < totalSlides) {
      nextSlide = MAX_SLIDE
    } else if (currentSlide === MAX_SLIDE || nextSlide > totalSlides) {
      nextSlide = 0
    }

    prevSlide = currentSlide - scrollStep

    if (prevSlide < 0) {
      if (Math.abs(prevSlide) < step) {
        prevSlide = 0
      } else {
        prevSlide = totalSlides - scrollStep
      }
    }
  }

  const trackGA4Events = () => {
    switch (carouselType) {
      case 'article':
        GA4(
          hasMultipleItemsPerView
            ? 'carousel_articles_scroll'
            : 'carousel_full_bleed_scroll',
          {
            component_headline: introductionTitle,
            village_name: villageName,
          }
        )
        break
      case 'image':
        GA4('carousel_images_scroll', {
          component_headline: introductionTitle,
          village_name: villageName,
        })
        break
      case 'info':
        GA4('carousel_info_cards_scroll', {
          component_headline: introductionTitle,
          village_name: villageName,
        })
        break
      case 'product':
        GA4('carousel_products_card_scroll', {
          component_headline: introductionTitle,
          village_name: villageName,
        })
        break
      case 'GiftGuideProduct':
        GA4('gift_guide_products_carousel_scroll', {
          component_headline: introductionTitle,
          village_name: villageName,
        })
        break
      case 'RelatedArticles':
        GA4('carousel_related_articles_scroll', {
          component_headline: introductionTitle,
          village_name: villageName,
        })
        break
      case 'offer':
        GA4('offer_carousel_scroll', {
          component_headline: introductionTitle,
          village_name: villageName,
        })
        break
      case 'service':
        GA4('carousel_services_card_scroll', {
          component_headline: introductionTitle,
          village_name: villageName,
        })
        break
      default:
        break
    }
  }

  return (
    <CarouselButtonWrapper
      hasMultipleItemsPerView={hasMultipleItemsPerView}
      isBoxed={isBoxed}
      isSmall={isSmall}
      isMobileOnly={isMobileOnly}>
      <div>
        <CarouselButton
          disabled={currentSlide === 0}
          onClick={() => {
            trackGTM('Carousel', 'carousel', 'click', currentSlide)
            trackGA4Events()
            GA4('carousel_click', { village_name: villageName })
            onClick(prevSlide, 'left')
          }}>
          <ArrowIcon width="30px" transform="rotate(180deg)" />
        </CarouselButton>

        <CarouselButton
          disabled={nextSlide === (hasMultipleItemsPerView ? currentSlide : 0)}
          onClick={() => {
            trackGTM('Carousel', 'carousel', 'click', currentSlide)
            trackGA4Events()
            GA4('carousel_click', { village_name: villageName })
            onClick(nextSlide, 'right')
          }}>
          <ArrowIcon width="30px" />
        </CarouselButton>
      </div>
    </CarouselButtonWrapper>
  )
}

CarouselControls.propTypes = {
  currentSlide: PropTypes.number.isRequired,
  totalSlides: PropTypes.number.isRequired,
  onSlideChange: PropTypes.func.isRequired,
  hasMultipleItemsPerView: PropTypes.bool,
  isBoxed: PropTypes.bool,
  isSmall: PropTypes.bool,
  isMobileOnly: PropTypes.bool,
  villageName: PropTypes.string,
  carouselType: PropTypes.string,
  introductionTitle: PropTypes.string,
}

CarouselControls.defaultProps = {
  hasMultipleItemsPerView: false,
  isBoxed: false,
  isSmall: false,
  isMobileOnly: false,
  villageName: '',
  carouselType: '',
  introductionTitle: '',
}

export default CarouselControls
