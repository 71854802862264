import styled from 'styled-components'
import { themeGet } from 'styled-system'
import theme from 'styles/theme'
import { transitions } from 'polished'

export const CarouselButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  height: 0;
  overflow: visible;
  width: 100%;
  display: ${(props) => (props.isMobileOnly ? 'block' : 'none')};
  padding: 0 ${theme.space[3]};

  ${(props) => (props.hasMultipleItemsPerView ? `top: 0;` : `bottom: 0;`)}

  ${theme.mediaQueries.large} {
    display: ${(props) => (props.isMobileOnly ? 'none' : 'block')};
  }

  ${theme.mediaQueries.landscape} {
    display: ${(props) => (props.isMobileOnly ? 'none' : 'block')};
  }

  > div {
    max-width: ${theme.containterMaxWidth};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    margin: 0 auto;

    html[dir='rtl'] & {
      justify-content: flex-start;
    }
  }

  button {
    transform: translate3d(0, -70px, 0);

    ${theme.mediaQueries.large} {
      transform: translate3d(0, -100px, 0);
    }

    ${theme.mediaQueries.landscape} {
      transform: translate3d(0, -100px, 0);
    }
  }

  ${(props) =>
    props.hasMultipleItemsPerView &&
    !props.isSmall &&
    `button {
      transform: translate3d(0, 430px, 0);

      ${theme.mediaQueries.large} {
        transform: translate3d(0, 430px, 0);
      }
    }
  `}

  ${(props) =>
    props.hasMultipleItemsPerView &&
    props.isSmall &&
    `button {
      transform: translate3d(0, 230px, 0);

      ${theme.mediaQueries.large} {
        transform: translate3d(0, 230px, 0);
      }
    }
  `}

  ${(props) =>
    props.hasMultipleItemsPerView &&
    props.isBoxed &&
    `top: auto;
    bottom: 0;
    background: red;
    display: none;
    button {
      transform: translate3d(0, -70px, 0);

      ${theme.mediaQueries.large} {
        transform: translate3d(0, -70px, 0);
      }
    }
  `}
`

export const CarouselButton = styled.button`
  background: ${theme.colors.white};
  border: none;
  box-shadow: 0;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  width: 70px;
  ${transitions('background ease-in 0.2s', 'color ease-in 0.2s')};

  &:nth-child(2) {
    background: ${theme.colors.sagelight};
  }

  span {
    height: 40px;
  }

  &:hover:not(:disabled) {
    background: ${themeGet('colors.sagedark')};
    color: ${theme.colors.white};

    svg * {
      fill: ${theme.colors.white};
    }
  }

  &:disabled {
    opacity: 0.5;
    color: ${themeGet('colors.coallight')};
  }
`
