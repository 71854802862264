import { Body20, Title20, Title42 } from 'components/Typography'
import { GatsbyImage } from 'gatsby-plugin-image'
import { rem } from 'polished'
import styled, { keyframes, css } from 'styled-components'
import theme from 'styles/theme'
import { buttonShadow } from 'styles/sharedStyle'
import MarkdownRenderer from 'components/MarkdownRenderer'
import DefaultButton from 'components/Button'

export const bounceFromTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  }
  100% {
    transform: translateY(0);
  }
`

export const Wrapper = styled.aside`
  animation: ${css`
    ${bounceFromTop} 0.6s
  `};
  align-items: center;
  background: transparent;
  color: ${theme.colors.white};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 15px 25px;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 0.3s ease, visibility 0.3s;
  width: 100%;
  z-index: 99;
  pointer-events: none;
  ${({ showing }) => `
    opacity: ${showing ? '1' : '0'};
    visibility: ${showing ? 'visible' : 'hidden'};
  `}
  ${theme.mediaQueries.mobile} {
    justify-content: center;
    padding: 15px 15px;
  }

  p,
  a,
  span {
    font-family: BrownStd-Light, sans-serif;
  }
`

export const WrapperMobile = styled.aside`
  animation: ${css`
    ${bounceFromTop} 1s
  `};
  align-items: center;
  background: transparent;
  color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  text-align: center;
  p,
  a,
  span {
    font-family: BrownStd-Light, sans-serif;
  }
  pointer-events: initial;
`

export const OfferBorderContainer = styled.div`
  width: 100%;
  backdrop-filter: blur(25px);
  padding: 10px;
  pointer-events: initial;

  ${theme.mediaQueries.small} {
    max-width: 586px;
  }
`

export const OfferContainer = styled.div.attrs((props) => props)`
  display: flex;
  flex-direction: column;
  max-width: 648px;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 25px 25px 75px 25px;
  background: ${(props) => `#${props.background}`};
  color: ${(props) => `#${props.invertedColor}`};
  box-shadow: 0px 4.155px 15.581px 5.194px rgba(0, 0, 0, 0.15);
  pointer-events: initial;

  ${theme.mediaQueries.mobile} {
    padding: 13px 13px 26px 13px;
  }
`

export const MembersOnly = styled(Body20)`
  font-size: ${rem(18)};
  font-family: BrownStd-Light, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  ${theme.mediaQueries.small} {
    font-size: ${rem(21)};
  }
`

export const OfferDates = styled(Title20)`
  font-size: ${rem(14)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-family: BrownStd-Light, sans-serif;

  ${theme.mediaQueries.small} {
    font-size: ${rem(21)};
  }
`

export const OfferBrand = styled(Title20)`
  font-size: ${rem(16)};
  font-family: BrownStd-Light, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;

  ${theme.mediaQueries.medium} {
    font-size: ${rem(32)};
  }

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(16)};
  }
`

export const OfferTitle = styled(Title42)`
  color: ${(props) => `#${props.color}`};
  font-size: ${rem(16)};
  font-weight: 300;
  word-wrap: break-word;
  word-break: break-all;

  ${theme.mediaQueries.medium} {
    font-size: ${rem(32)};
  }

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(16)};
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.single ? 'center' : 'space-between')};
  width: ${(props) => (props.single ? 'auto' : '100%')};
  margin-top: ${rem(16)};
  gap: ${rem(12)};

  ${theme.mediaQueries.small} {
    width: 100%;
  }
  ${theme.mediaQueries.mobile} {
    width: 100%;
    gap: ${rem(16)};
    margin-top: ${rem(16)};
  }
`

export const Button = styled(DefaultButton)`
  ${buttonShadow}
  text-decoration: none;
  height: initial;
  padding: 1rem 2rem;
  width: 50%;
  margin-right: 15px;
  font-size: ${rem(9.6)};
  font-family BrownStd-Regular;
  font-weight: 400;
  color: ${(props) => `#${props.backgroundColor}`};
  background: ${(props) => `#${props.color}`};

  ${theme.mediaQueries.small} {
    margin-right: 10px;
    margin-bottom: 0px;
    }

  span {
    line-height: initial;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: initial;
  }

  ${theme.mediaQueries.medium} {
    font-size: ${rem(20.18)};
    padding: 1rem 3rem;
  }
`

export const PhantonButton = styled(DefaultButton)`
  text-decoration: none;
  height: initial;
  padding: 1rem;
  min-width: 50%;
  font-size: ${rem(9.6)};
  font-family BrownStd-Regular;
  font-weight: 400;
  border-color: ${(props) => `#${props.color}`};
  color: ${(props) => `#${props.color}`};

  span {
    line-height: initial;
    font-family: inherit;
    font-size: inherit;
    letter-spacing: initial;
  }

  ${theme.mediaQueries.small} {
    width: 50%;
  }

  ${theme.mediaQueries.medium} {
    font-size: ${rem(20.18)};
  }
`

export const OfferDetails = styled(Body20)`
  text-align: center;
  margin-top: ${rem(25)};
  font-family: BrownStd-Light, sans-serif;
  font-size: 12px;
  ${theme.mediaQueries.small} {
    font-size: 16px;
  }
  ${theme.mediaQueries.mobile} {
    margin-top: ${rem(19)};
  }
`

export const OfferSubtitle = styled(Title20)`
  ${theme.textStyles.title16}
  text-align: center;
  font-size: 16px;
  ${theme.mediaQueries.small} {
    font-size: 30px;
  }
`

export const OfferTerms = styled(MarkdownRenderer)`
  ${theme.textStyles.title16}
  font-size: ${rem(10)};
  text-align: center;
  font-family: BrownStd-Light, serif;
  letter-spacing: initial;
  line-height: 180%;

  span, p, a, li {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  ${theme.mediaQueries.medium} {
    font-family: BrownStd-Light, serif;
    font-size: ${rem(14)};
  }
`

export const BackAllOffer = styled(Body20)`
  display: flex;
  align-items: center;
  margin-top: auto;
  text-align: center;
  justify-content: center;
  width: 100%;
  font-size: ${rem(12)};
  span {
    align-self: center;
    height: 23px;
    svg {
      width: 23px;
      height: 23px;
    }
  }
  ${theme.mediaQueries.small} {
    font-size: ${rem(16)};
    span {
      height: 36px;
      svg {
        width: 36px;
        height: 36px;
      }
    }
  }
`

export const GridPlacement = styled.div`
  grid-column: 1 / 3;
  grid-row: ${({ gridRow }) => gridRow};
`

export const Closer = styled.button`
  appearance: none;
  background: none;
  border-radius: 0;
  border: 0 none;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  display: block;
  flex: none;
  margin: 0 0 9px auto;
  outline: none;
  padding: 0;
  width: 22px;
  height: 22px;

  ${theme.mediaQueries.small} {
    margin: 0 0px 9px auto;
    width: 36px;
    height: 36px;
  }

  ${({ hexcode }) => `
    background-color: ${hexcode && hexcode};
    border-radius: 50%;
  `}

  &::before {
    content: '';
    display: block;
    height: 100%;
    position: relative;
    transform: rotate(45deg);
    width: 100%;

    ${({ color }) => `
      background: ${
        color
          ? `linear-gradient(${color}, ${color}) 50% 50% / 1px 100%
        no-repeat,
      linear-gradient(${color}, ${color}) 50% 50% / 100% 1px no-repeat`
          : `linear-gradient(currentColor, currentColor) 50% 50% / 1px 100%
        no-repeat,
      linear-gradient(currentColor, currentColor) 50% 50% / 100% 1px no-repeat;`
      };
    `}

    width: 50%;
    height: 50%;
    margin: 0 auto;
  }
`
export const CustomSpacing = styled.div`
  display: block;
  width: 100%;
  position: relative;
  height: ${rem(78)};

  ${theme.mediaQueries.mobile} {
    height: ${rem(16)};
  }
`

export const OfferPrices = styled(Body20)`
  ${theme.textStyles.body20}
  text-align: center;
  font-size: ${rem(10)}
  font-weight: 300;
  font-family: BrownStd-Light, sans-serif;

  ${theme.mediaQueries.medium} {
    font-size: ${rem(20)};
  }
  ${theme.mediaQueries.mobile} {
    font-size: ${rem(10)}
  }
`

export const OfferPrice = styled.span.attrs((props) => props)`
  position: relative;

  &::before {
    background-color: ${theme.colors.white};
    content: '';
    display: ${(props) => (props.strikeThrough ? 'block' : 'none')};
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: rotate(-3deg) translateY(-50%);
  }
`

export const OfferPriceLabel = styled.span.attrs((props) => props)`
  padding: 0 ${rem(5)} 0 ${rem(15)};

  ${theme.mediaQueries.mobile} {
    padding: 0 ${rem(2)} 0 ${rem(8)};
  }
`

export const OfferImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  gap: 2px;
  flex-wrap: wrap;
  box-sizing: border-box;
`

export const OfferButton = styled.button`
  width: 315px;
  padding: ${rem(20)} ${rem(20)};
  font-size: ${rem(18)};
  border: 1px solid
    ${(props) =>
      props.borderColour ? `#${props.borderColour}` : `transparent`};
  background-color: ${(props) =>
    props.backgroundColour ? `#${props.backgroundColour}` : `transparent`};
  color: ${theme.colors.white};
  text-transform: uppercase;
  ${(props) =>
    props.shadow ? `box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.10)` : null};

  ${theme.mediaQueries.mobile} {
    width: 50%;
    padding: ${rem(16)} ${rem(12)};
    font-size: ${rem(10)};
  }
`

export const ImageContainer = styled.div`
  display: flex;
  min-width: 100%;
  height: 100%;
  justify-content: ${(props) =>
    props.count === 1 ? 'center' : 'space-between'};
  align-items: center;
  overflow: hidden;
  gap: ${rem(12)};

  ${theme.mediaQueries.mobile} {
    gap: ${rem(16)};
  }
`

export const ImageStyle = styled(GatsbyImage)`
  width: 312px;
  height: 370px;
  padding: 4px;

  img {
    object-fit: cover !important;
    border: ${rem(4.2)} solid rgba(255, 255, 255, 0.2);
  }

  ${theme.mediaQueries.mobile} {
    width: 50%;
    height: auto;

    img {
      border: ${rem(2)} solid rgba(255, 255, 255, 0.2);
    }
  }
`
