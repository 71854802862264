/* eslint-disable import/prefer-default-export */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  contextDefinition,
  contextInitialState,
  extractProducts,
} from './constants'

const ProductGuideContentContext = ({ children, content, underPrice, abovePrice }) => {
  const nomalizedData = useMemo(() => extractProducts(content), [content])  
  const promoGiftCards = []

  return (
    <contextDefinition.Provider
      value={{
        ...contextInitialState,
        products: nomalizedData.products,
        editions: nomalizedData.editions,
        promoCrossSellingCards: nomalizedData.promoCrossSellingCards,
        promoGiftCards,
        underPrice,
        abovePrice,
      }}
    >
      {children}
    </contextDefinition.Provider>
  )
}

ProductGuideContentContext.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object, null]),
  content: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  underPrice: PropTypes.number,
  abovePrice: PropTypes.number,
}

ProductGuideContentContext.defaultProps = {
  children: null,
  content: [],
  underPrice: null,
  abovePrice: null,
}

export * from './constants'
export default ProductGuideContentContext